import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Utils } from '../../../../utils';
import { Link } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { BaseHttpService } from 'services/base';
import DeleteDialog from '../shared/DeleteDialog';
import CustomModal from 'view/components/modals/CustomModal';
import SupportMessageReply from '../forms/shared/SupportMessageReply';

function UserSupportMessagesList({ onSelect }) {
  const [data, setData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [selectedSupportMessage, setSelectedSupportMessage] = useState(null);

  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
    _getData(newPageNumber)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  };

  useEffect(() => {

    _getData(1)

    return () => {
    }
  }, [])

  const _getData = (pageNumber) => {

    BaseHttpService.loadUserSupportMessages(pageNumber).then((items) => {
      if (items.results) {
        setData(items.results)
        setCount(items.count)
        setTotalPages(Math.ceil(items.count / 30));
      }
    });

  }

  const handleDelete = (e, id) => {
    setSelectedId(id);
    setRefresh(refresh + 1);
    setDeleteDialogIsOpen(true);
  };


  const handleEdit = (e, support_message) => {
    e.preventDefault();
    setSelectedSupportMessage(support_message);
    setRefresh(refresh + 1);
    setEditModalIsOpen(true);
  };


  return (
    <div className='box'>
      <div className="box-body general-list">
        {/* count: {count} */}
        <table className="table table-striped">
          {/* <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th className='hide-on-small'>Subject </th>
              <th className='hide-on-small'>Date </th>
              <th></th>
            </tr>
          </thead> */}
          <tbody>
            {data && data.map(item => (
              <tr key={"people_item_" + item.id} className='pointer' onClick={(e) => { handleEdit(e, item) }} >
                <td><Link  ><Avatar alt={Utils.getDefaultName(item)} src={Utils.getUserImage(item.user.profile)} /></Link></td>
                <td className='kfont'>
                  <Link  >
                    {item.user.profile.first_name} {item.user.profile.last_name}
                  </Link>
                </td>
                <td className='hide-on-small kfont'>{item.title} </td>
                <td className='hide-on-small'>{Utils.getDate(item.created_at)} </td>
                <td className='text-right'>
                  <a href="#" onClick={(e) => { e.stopPropagation(); handleDelete(e, item.id) }} className="btn btn-outline btn-swap-1">
                    <span><i className="fas fa-trash color-gray "></i></span>
                    <span>Delete <i className="fas fa-trash ms-2 color-gray "></i></span>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>

        </table>

        {totalPages>1 && (
          <ul className="pagination">
            <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(pageNumber - 1)} disabled={pageNumber === 1}>
                <i className="fas fa-angle-left"></i>
              </button>
            </li>
            {Array.from({ length: totalPages }, (_, index) => (
              <li key={index} className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                  {index + 1}
                </button>
              </li>
            ))}
            <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(pageNumber + 1)} disabled={pageNumber === totalPages}>
                <i className="fas fa-angle-right"></i>
              </button>
            </li>
          </ul>
        )}
      </div>


      <DeleteDialog
        url={`/api/supportmessages/${selectedId}`}
        isOpen={deleteDialogIsOpen}
        passwordNeeded={false}
        key={"mdl" + deleteDialogIsOpen + selectedId}
        setOpen={setDeleteDialogIsOpen}
        notifyDone={() => { _getData(pageNumber); setDeleteDialogIsOpen(false) }}
      />

      {selectedSupportMessage && (
        <CustomModal big key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
          open={editModalIsOpen}  >

          <div className="kfont lan-rtl ">
            <div className="featured-box featured-box-effect-2 featured-box-primary border-all-light border-radius box-shadow-none box-shadow-1 box-shadow-1-hover border-color-transparent-hover">
              <div className="box-content d-flex border-0 p-1 text-left lan-text-right">
                <div className="mr-2 p-3 ">
                  {/* <i className="icon-featured icon-featured-small fa fa-envelope"></i> */}
                  <Avatar className='' title={selectedSupportMessage?.user.profile.first_name} alt={selectedSupportMessage?.user.profile.first_name} src={Utils.getProfileImage(selectedSupportMessage?.user.profile)} />

                </div>
                <div className="">
                  <h4 className="font-weight-normal text-5 text-dark">{selectedSupportMessage?.title}</h4>
                  <p className="mb-0 text-left lan-text-right">{selectedSupportMessage?.message}</p>
                </div>
              </div>
            </div>
          </div>

          <SupportMessageReply supportMessageId={selectedSupportMessage.id} />

        </CustomModal>
      )}



    </div>
  )
}

export default UserSupportMessagesList