import React, { Suspense, lazy, useEffect } from 'react'
import { useQuery } from 'react-query';
import { CourseHttpService } from 'services/course';
import { Utils } from 'utils';
import CourseCard from './CourseCard';
import { useState } from 'react';
import PodcastCard from './PodcastCard';

// const CourseCard = lazy(() => import('./CourseCard'));


function PodcastsGrid({query}) {

    const[ podcasts,setPodcasts] = useState(null)

    const { isLoading, error, data, refetch } = useQuery('podcasts', ()=>{ return CourseHttpService.loadPodcasts(query) }, {
        onSuccess: (data) => {
            setPodcasts(data.results)
        },
        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
        refetchInterval: 600 * 100000,
    });

    useEffect(() => {
        refetch()
    }, [query])

    return (
        <div className='mt-1'>
            <div className="row py-3 ">
                {podcasts?.map((podcast, i) => (
                    <div className="col-sm-6 col-md-6 mb-4 mb-md-0 " data-appear-animation="fadeIn" data-appear-animation-delay={i * 250} key={podcast.id}>
                        {/* <Suspense fallback={<div>Loading...</div>}> */}
                            <PodcastCard showDescription={false} podcast={podcast} />
                        {/* </Suspense> */}
                    </div>
                ))}
               
            </div>
        </div>
    )
}

export default PodcastsGrid