import React, { useEffect, useState } from 'react'
import CmsHeader from 'view/cms/components/shared/CmsHeader'
import { useParams } from 'react-router-dom'
import { Utils } from 'utils'
import { CourseHttpService } from 'services/course'
import ContentForm from 'view/cms/components/forms/course/ContentForm'
import ContentTranscriptView from 'view/cms/components/Widgets/course/content/ContentTranscriptView'
import ContentLRCView from 'view/cms/components/Widgets/course/content/ContentLRCView'

function ContentDetailsPage() {

    const [data, setData] = useState(null);
    const [count, setCount] = useState(0);
    const [show_form, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);

    const { id } = useParams();

    useEffect(() => {

        if (id) {
            _getData()
        }

    }, [])

    const _getData = () => {
        setLoading(true);
        CourseHttpService.loadContent(id).then(item => {
            setData(item)
            setLoading(false);
        })
    }

    return (
        <div className="body">
            <CmsHeader />

            <div role="main" className="main">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg-12">
                            <div className="flex-align-items-center flex-space-between mb-3">
                                <h2 className='mb-0'>{data && Utils.getDefaultName(data)}</h2>
                                <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); setShowForm(!show_form) }}>
                                    <i className="fas fa-edit" ></i>
                                </button>
                            </div>
                            {show_form && (
                                    <ContentForm id={id} lesson={data?.lesson} />
                            )}
                        </div>
                        {/* <ContentTranscriptView key={data+"cnt"} content={data} /> */}
                        <ContentLRCView key={data+"cnt"} content={data} />
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

export default ContentDetailsPage