import React from 'react'
import { Utils } from 'utils';

function VideoQuestionIndicators({ videoElement, content,onQuestionClick }) {

    if (!videoElement) return;

    return (
        <>
            {content?.questions.map(quesion => {
                const progressBarWidth = videoElement.offsetWidth;
                let percentage = (Utils.timeInSeconds(quesion.show_at_time) / videoElement.duration) * 100;
                if (percentage > 100) percentage = 100;
                const divPosition = (percentage * progressBarWidth) / 100;
                return (
                    <div key={'vqi'+quesion.id} onClick={()=>{ onQuestionClick(quesion)  }} className='video-question-indicator' style={{ left: `${divPosition}px`, }} />
                )
            })}
        </>
    )
}

export default VideoQuestionIndicators