import React, { useEffect, useRef } from 'react'
import Footer from '../../components/shared/Footer'
import Header from '../../components/shared/Header'
import SlideShow from '../../components/shared/home/SlideShow'
import { Utils } from '../../../utils';
import HomeCourses from 'view/features/feature_home/components/HomeCourses';
import HomeAbout from 'view/features/feature_home/components/HomeAbout';
import FaqsList from 'view/features/feature_home/components/FaqsList';
import BlogStyle5 from 'view/features/feature_blog/components/blog_styles/BlogStyle5';
import PodcastsHomeGrid from 'view/components/course/components/PodcastsHomeGrid';
import NotationsGridHome from 'view/components/course/components/NotationsGridHome';
import PostCarousel from 'view/components/shared/home/PostCarousel';
import HomeTutorials from 'view/features/feature_home/components/HomeTutorials';
import HomeTutorialsCarousel from 'view/features/feature_home/components/HomeTutorialsCarousel';

function Home() {

	var initiatedRef = false;

	useEffect(() => {
		if (!initiatedRef) {
			Utils.initiateTheme()
			initiatedRef = true;
		}
	}, [])

	return (
		<div className="body">
			<Header transparent={1} />

			<div role="main" className="main">

				<SlideShow />
				<HomeCourses />
				<HomeAbout />

				<section id="tutorials">
					<div className="bg-color-dark p-relative z-index-1 custom-section-1 bg-position-center bg-size-cover lazyload" style={{ backgroundImage: "url(img/demos/creative-agency-2/backgrounds/background-1.jpg)" }}>
						<div className="container py-4">
							<div className="row align-items-center pt-3 pb-5 mt-3 mb-5 ">
								<div className="col-lg-12">
									<HomeTutorialsCarousel />
								</div>
							</div>
						</div>
					</div>
				</section>

				<div className="container bg-color-light p-relative z-index-2 py-4 px-4 mt-3 home-blog-container" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250">
					<NotationsGridHome />
				</div>

				

				<section id="podcasts">
					<div className="bg-color-dark p-relative z-index-1 custom-section-1 bg-position-center bg-size-cover lazyload" style={{ backgroundImage: "url(img/demos/creative-agency-2/backgrounds/background-1.jpg)" }}>
						<div className="container py-4">
							<div className="row align-items-center pt-3 pb-5 mt-3 mb-5 ">
								<div className="col-lg-12">
									<PodcastsHomeGrid />
								</div>
							</div>
						</div>
					</div>
				</section>

				<div className="container bg-color-light p-relative z-index-2 py-4 px-4 mt-3 home-blog-container" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="250">
					{/* <BlogStyle5 /> */}
					<PostCarousel />
				</div>

				{/* <HomePostsCarousel /> */}

			</div>

			<Footer />
		</div>


	)
}

export default Home