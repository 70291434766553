import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Utils, translate } from '../../../../utils'
import TranslateController from 'controller/shared/TranslateController';

function PodcastCard({ podcast, showDescription = true }) {
    const { _t } = TranslateController()



    return (
        <div className='mb-4'>
            {/* <Link to={`/podcast/${podcast.id}`}> */}
                <span className="thumb-info thumb-info-swap-content anim-hover-inner-wrapper">
                    <span className="thumb-info-wrapper overlay overlay-show overlay-gradient-bottom-content">
                        <img src={podcast.image} className="img-fluid" alt={Utils.getDefaultName(podcast)} />
                        <span className="thumb-info-title thumb-info-title-vert-align bg-transparent w-100 text-center mw-100 p-0">
                            <span className="anim-hover-inner-translate-top-40px transition-2ms d-inline-block">
                                {/* <span className="thumb-info-inner text-4 kfont">{Utils.getDefaultName(podcast)}</span> */}
                            </span>

                        </span>


                        <span className="thumb-info-title bottom-30 bg-transparent w-100 mw-100 p-0 text-center">
                            <span className="thumb-info-swap-content-wrapper">
                                <span className="thumb-info-inner">
                                    {/* <a href={`/podcast/${podcast.id}`} className="btn btn-primary btn-circle"><i className="fas fa-arrow-right"></i>
                                    </a> */}
                                    <a href={`/podcast/${podcast.id}`} ><h2 className='text-light'>{Utils.getDefaultName(podcast)}</h2></a>
                                </span>
                                <span className="thumb-info-inner text-2 lan-rtl">
                                    {showDescription && (
                                        <div className="px-5 text-2 opacity-7 font-weight-medium text-light mb-2  kfont line-height-15">{Utils.parseShortDescription(podcast)}</div>
                                    )}
                                    {/* <p className='text-2 font-weight-light text-light'>{_t("Writer")+" : "}{podcast?.writer && Utils.getDefaultName(podcast?.writer)}</p> */}
                                    <a href={`/podcast/${podcast.id}`} ><h2 className='text-light'>{Utils.getDefaultName(podcast)}</h2></a>

                                    <p className='text-2 font-weight-light text-light'>{_t("Level")+" : "}{podcast?.level && Utils.getDefaultName(podcast.level)}</p>
                                    <a href={`/podcast/${podcast.id}`} className="btn btn-modern btn-primary btn-arrow-effect-1 py-2 px-3  kfont">{_t("View podcast")} <i className="fas fa-arrow-right ms-2"></i></a>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            {/* </Link> */}
        </div>

    )
}

export default PodcastCard