import TranslateController from 'controller/shared/TranslateController'
import React, { useEffect, useState } from 'react'
import SupportMessageForm from 'view/cms/components/forms/shared/SupportMessageForm'
import UserSupportMessagesList from 'view/cms/components/lists/UserSupportMessagesList'
import Done from 'view/cms/components/shared/Done'
import PageLayout from 'view/components/shared/PageLayout'

function UserSupportMessages() {
    const { _t } = TranslateController()
    const [addNew, setAddNew] = useState(false);
    const [refresh, setRefresh] = useState(1);
    const [done, setDone] = useState(false);

    return (
        <PageLayout showbreadcrumbs={false} HeaderChild={
            <h1 className="text-color-light my-3 font-weight-bold text-10 text-sm-8 lan-kfont">{_t("Support Messages")}</h1>
        } >

            <div className="row ">
                <div className="col-md-12 mb-4 lan-text-right">
                    <div className="form-group" style={{ width: 200 }}>
                        <button type="submit" onClick={() => { setAddNew(!addNew); }}
                            className="btn btn-modern btn-dark w-100 font-weight-semibold text-capitalize text-3 py-3 anim-hover-translate-top-5px transition-2ms mb-5 mb-lg-0"
                        >
                            <span className="px-4 d-block ws-nowrap kfont">{_t("New Message")} </span></button>
                    </div>
                    {addNew && (
                        <SupportMessageForm onDone={()=>{ setRefresh(refresh+1); setAddNew(false); setDone(true); }} />
                    )}
                    {done && <Done />}
                </div>
                <div className="col-md-12 mb-4">
                    <UserSupportMessagesList key={refresh+"usml"}  />
                </div>
            </div>

        </PageLayout>


    )
}

export default UserSupportMessages