import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CourseHttpService } from "services/course";
import { Utils } from "utils";

function ContentReadingController(content) {

    const [is_completed, setIsCompleted] = useState(false)
    const [loading, setLoading] = useState(false)

    var navigate = useNavigate()
    
    const { course, lesson } = useParams();


    useEffect(() => {
        let isDone = (content?.course_logs && content?.course_logs.length > 0) && content?.course_logs[0].ended != null
        if (isDone) {
            setIsCompleted(true)
        }
    }, [content?.id]);

    const logContent = (action = "start") => {
        var body = {
            "content": content?.id,
            "action": action
        }

        console.log(body);
        setLoading(true)
        CourseHttpService.logContent(body).then(response => {
            setLoading(false)
            setIsCompleted(true)
        }).catch(err => {
            // setError("Something went wrong")
        });
    }

    const goToNextItem = () => {
        console.log(content)
        // navigate(`/content/${content.id}`) 
        let showQuizNext = false;


        if (content?.next_content == null && content.next_quiz != null) {
            showQuizNext = true;
        }

        if (content?.next_content != null && content?.next_content?.lesson !== content.lesson) {
            //next content is from next lesson, so show Quizes first
            showQuizNext = true;
        }

        if(showQuizNext) {
            let quiz = content.next_quiz
            navigate(`/course/${course}/lesson/${quiz?.lesson}/quiz/${quiz?.id}`)
            Utils.scrollTo(0)
        }
        else if(content?.next_content){
            let next_content = content.content
            navigate(`/course/${course}/lesson/${content?.lesson}/content/${next_content?.id}`)
            Utils.scrollTo(0)
        }
    }




    return { logContent, is_completed, loading, goToNextItem }
}

export default ContentReadingController