import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { CourseHttpService } from 'services/course';
import { useQuery } from 'react-query';
import { HttpService } from 'services/http';
import { Utils } from 'utils';

const ProgressByCourseChart = () => {

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

    const [overallStats, setOverallStats] = useState({
        totalEnrollment: 0,
        totalCompletion: 0,
        averageProgress: 0,
    });

    const { isLoading, error, data, refetch } = useQuery('course-statistics', () => { return CourseHttpService.loadCourseStatistics() }, {
        onSuccess: (data) => {
            const courseNames = data.map((item) => Utils.getDefaultName(item.course));
            const enrollmentCounts = data.map((item) => item.enrollment_count);
            const completionCounts = data.map((item) => item.completion_count);
            const averageProgress = data.map((item) => item.average_progress);

            // Calculate overall stats
            const totalEnrollment = enrollmentCounts.reduce((a, b) => a + b, 0);
            const totalCompletion = completionCounts.reduce((a, b) => a + b, 0);
            const averageProgressTotal = averageProgress.reduce((a, b) => a + b, 0) / averageProgress.length;

            setChartData({
                labels: courseNames,
                datasets: [
                    {
                        label: 'Enrollment Count',
                        data: enrollmentCounts,
                        backgroundColor: 'rgba(54, 162, 235, 0.6)',
                    },
                    {
                        label: 'Completion Count',
                        data: completionCounts,
                        backgroundColor: 'rgba(255, 99, 132, 0.6)',
                    },
                    {
                        label: 'Average Progress',
                        data: averageProgress,
                        backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    },
                ],
            });

            setOverallStats({
                totalEnrollment,
                totalCompletion,
                averageProgress: averageProgressTotal,
            });
        },
        refetchInterval: HttpService.DefaultRefetchInterval,
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading data</div>;
    }

    return (
        <section className="">
            <p className="p-2">Number of users</p>
            <div className="row">
                <div className="col-md-8">
                    <Bar data={chartData} options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                display: true,
                                position: 'bottom',
                                labels: {
                                    padding: 30,
                                }
                            },
                        },
                    }} />
                </div>
                <div className="col-md-4">
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <th>Total Enrollment</th>
                                <td>{overallStats.totalEnrollment}</td>
                            </tr>
                            <tr>
                                <th>Total Completion</th>
                                <td>{overallStats.totalCompletion}</td>
                            </tr>
                            <tr>
                                <th>Total Progress</th>
                                <td>{overallStats.averageProgress.toFixed(2)} %</td>
                            </tr>
                           
                        </tbody>
                    </table>
                </div>
            </div>
            
        </section>
    );
};

export default ProgressByCourseChart;
