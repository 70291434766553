import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { CourseHttpService } from 'services/course';
import { Utils } from 'utils'

function ContentLrcSummary({ content, onTranscriptClicked, progress = -1 }) {

  const [lyrics, setData] = useState(null);

  const { isLoading, error, data, refetch } = useQuery(['lrcs', content.id], () => { return CourseHttpService.loadLrcs(content.id) }, {
    onSuccess: (data) => {

      if (data.results && data.results.length > 0) {
        let lrcs = parseLrcText(data.results[0].lrc_text)
        setData(lrcs)
      }

    },
    refetchInterval: 60 * 100000,
  });



  function parseLrcText(lrcText) {
    const lines = lrcText.split('\n');
    const lrcArray = lines.map(line => {
      // Match the time format [mm:ss] and capture text after the time
      const match = line.match(/\[(\d{2}):(\d{2})\](.+)/);
      if (match) {
        const minutes = match[1];
        const seconds = match[2];
        const text = match[3].trim();
        const time = `${minutes}:${seconds}`; // Keep time as mm:ss
        return { time, text };
      }
      return null;
    }).filter(item => item !== null); // Filter out any lines that did not match the pattern

    return lrcArray;
  }


  return (
    <div>
      {lyrics?.map((lyric, index) => {

        let transcriptToShowIndex = null;
        for (let i = lyrics.length - 1; i >= 0; i--) {
            if (Utils.isLrcVisible(lyrics[i], progress)) {
                transcriptToShowIndex =i;
                break;
            }
        }

        return (
          <div key={index + "lrc"} className={`transcript-paragraph ${(lyric && transcriptToShowIndex==index) ? 'active' : ""}`} onClick={() => { onTranscriptClicked(lyric) }}>
            {lyric.text}
          </div>
        )
      }
      )}
    </div>
  )
}

export default ContentLrcSummary