import React from 'react'
import CourseStatisticsChart from './charts/CourseStatisticsChart'
import UserStatisticsChart from './charts/UserStatisticsChart'
import ProgressByCourseChart from './charts/ProgressByCourseChart'

function CmsCharts() {
    return (
        <>
            <div className="row mt-5">
                {/* <div className="col-lg-6">
                    <CourseStatisticsChart />
                </div> */}
                <div className="col-lg-12">
                    <ProgressByCourseChart />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-lg-12">
                    <UserStatisticsChart />
                </div>
            </div>
            
        </>
    )
}

export default CmsCharts