import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { CourseHttpService } from '../../../services/course'
import InstructorAbout from '../../components/course/instructor/InstructorAbout'
import InstructorCourses from '../../components/course/instructor/InstructorCourses'
import InstructorMenu from '../../components/course/instructor/InstructorMenu'
import InstructorSummary from '../../components/course/instructor/InstructorSummary'
import Footer from '../../components/shared/Footer'
import Header from '../../components/shared/Header'
import PageHeader from 'view/components/shared/PageHeader'

function InstructorDetails() {
	const { id } = useParams();

	const { isLoading, error, data } = useQuery(['instructor', id], () => { return CourseHttpService.loadInstructor(id) }, {
		onSuccess: (data) => {
			console.log(data)
		}
	});


	return (
		<div className="body">
			<Header />

			<div role="main" className="main">
				<PageHeader child={<InstructorSummary instructor={data} />} showbreadcrumbs={false} />

				<div id='main'>
					<div className='custom-page-content p-relative z-index-2'>
						<div className="container custom-padding-top-1 custom-padding-bottom-1 px-lg-5 bg-light">
							{/* <InstructorMenu instructor={data} /> */}
							<InstructorAbout instructor={data} />
							<InstructorCourses instructor={data} />
						</div>
					</div>
				</div>

			</div>

			<Footer />
		</div>


	)
}

export default InstructorDetails