import React, { useContext, useEffect, useState } from 'react'
import { Utils, translate } from '../../../utils'
import CoursesGrid from '../../components/course/components/CoursesGrid'
import PageLayout from 'view/components/shared/PageLayout'
import { LanguageContext } from 'contexts/LanguageContext'
import NotationsGrid from 'view/components/course/components/NotationsGrid'
import TranslateController from 'controller/shared/TranslateController'
import CustomModal from 'view/components/modals/CustomModal'
import SelectLevel from 'view/cms/components/forms/course/selects/SelectLevel'
import SelectInstructorList from 'view/cms/components/lists/SelectInstructorList'
import SelectScale from 'view/cms/features/feature_notation/components/SelectScale'
import SelectTimeSignature from 'view/cms/features/feature_notation/components/SelectTimeSignature'

function Notations() {

    const [query, setQuery] = useState()

    useEffect(() => {
        // Utils.initiateTheme()
    }, [])


    return (
        <PageLayout showbreadcrumbs={false} HeaderChild={<HeaderChild query={query} setSearchQuery={setQuery} />}>
            {/* <div className="col-md-4 mx-md-auto p-0">
								<FilterCoursesAside />
							</div> */}
            <NotationsGrid query={query} />
        </PageLayout>


    )
}



function HeaderChild({ setSearchQuery }) {
    const { _t } = TranslateController();
    const [query, setQuery] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState({
        name: "",
        time_signature: "",
        scale: "",
        level: "",
        artists: "",
        writers: "",
    });
    const [selectedArtists, setSelectedArtists] = useState([]);
    const [selectedWriters, setSelectedWriters] = useState([]);
    const [searchModalIsOpen, setSearchModalIsOpen] = useState(false);
    const [artistModalIsOpen, setArtistModalIsOpen] = useState(false);
    const [writerModalIsOpen, setWriterModalIsOpen] = useState(false);

    const handleAdvancedSearchChange = (e) => {
        const { name, value } = e.target;
        setAdvancedSearch(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleInstructorSelect = (field, instructor) => {
        if (field === 'artist') {
            setSelectedArtists(prevSelectedArtists => [...prevSelectedArtists, instructor]);
            setAdvancedSearch(prevState => ({
                ...prevState,
                artists: [...(prevState.artists || []), instructor.id]
            }));
        } else if (field === 'writer') {
            setSelectedWriters(prevSelectedWriters => [...prevSelectedWriters, instructor]);
            setAdvancedSearch(prevState => ({
                ...prevState,
                writers: [...(prevState.writers || []), instructor.id]
            }));
        }
    };

    const buildQueryString = () => {
        let queryString = `?size=100`;
        for (const key in advancedSearch) {
            if (advancedSearch[key]) {
                queryString += `&${key}=${advancedSearch[key]}`;
            }
        }
        return queryString;
    };

    const handleAdvancedSearchSubmit = (e) => {
        e.preventDefault();
        const queryString = buildQueryString();
        setSearchQuery(queryString);
        setSearchModalIsOpen(false);
    };

    return (
        <>
            <h1 className="text-color-light pt-3 pb-2 font-weight-bold text-10 text-sm-8">{_t("Notations")}</h1>
            <form action="" onSubmit={(e) => { e.preventDefault(); setSearchQuery(`?name=${query}`) }}>
                <div className="search-container">
                    <div className="simple-search input-group mt-2 mb-4">
                        <input className="form-control text-1 text-light" value={query} name="q" onChange={(e) => { setQuery(e.target.value) }} type="search" placeholder="Search..." />
                        <button className="btn" type="submit" aria-label="Search">
                            <i className="fas fa-search header-nav-top-icon"></i>
                        </button>
                        <button className="btn" type="button" aria-label="Advanced Search" onClick={() => setSearchModalIsOpen(true)}>
                            <i className="fas fa-sliders-h header-nav-top-icon text-light"></i>
                        </button>
                    </div>
                </div>
            </form>

            <CustomModal dark medium key={searchModalIsOpen} setOpen={(value) => setSearchModalIsOpen(value)} open={searchModalIsOpen}>
                <form onSubmit={handleAdvancedSearchSubmit} className='lan-rtl'>
                    <h2 className='text-light'>{_t("Advanced Search")}</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    placeholder={_t('Song Name')}
                                    name="name"
                                    value={advancedSearch.name}
                                    onChange={handleAdvancedSearchChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <SelectLevel
                                    id="level"
                                    placeholder={_t('Level')}
                                    name="level"
                                    width={"100%"}
                                    value={advancedSearch.level}
                                    callback={(value, name) => handleAdvancedSearchChange({ target: { name: 'level', value } })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <SelectScale
                                    id="scale"
                                    name="scale"
                                    placeholder={_t('Scale')}
                                    width={"100%"}
                                    value={advancedSearch.scale}
                                    callback={(value, name) => handleAdvancedSearchChange({ target: { name: 'scale', value } })}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <SelectTimeSignature
                                    id="time_signature"
                                    name="time_signature"
                                    placeholder={_t('Time Signature')}
                                    width={"100%"}
                                    value={advancedSearch.time_signature}
                                    callback={(value, name) => handleAdvancedSearchChange({ target: { name: 'time_signature', value } })}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <button type="button" className="btn btn-secondary lan-kfont" onClick={() => setArtistModalIsOpen(true)}>
                                    {_t("Select Artist")}
                                </button>
                                <div className="general-list my-4">
                                    {selectedArtists.map((artist, index) => (
                                        <div key={index+"sam"} className="">
                                            <div>{Utils.getDefaultName(artist)}, </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                {/* <button type="button" className="btn btn-secondary" onClick={() => setWriterModalIsOpen(true)}>
                                    {_t("Select Writer")}
                                </button> */}
                                <div className="general-list my-4">
                                    {selectedWriters.map((writer, index) => (
                                        <div key={index+"swm"} className="">
                                            <div>{Utils.getDefaultName(writer)}, </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>

                    <button type="submit" className="btn btn-primary mr-2 lan-ml-2 ">{_t("Search")}</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setSearchModalIsOpen(false)}>{_t("Close")}</button>
                </form>
            </CustomModal>

            {artistModalIsOpen && (
                <CustomModal big dark className="" key={artistModalIsOpen} setOpen={(value) => setArtistModalIsOpen(value)} open={artistModalIsOpen}>
                    <SelectInstructorList type={"artist"} showImage={false} notationId={null} OnSelect={(instructor) => { handleInstructorSelect('artist', instructor); setArtistModalIsOpen(false); }} />
                </CustomModal>
            )}

            {writerModalIsOpen && (
                <CustomModal big dark className="" key={writerModalIsOpen} setOpen={(value) => setWriterModalIsOpen(value)} open={writerModalIsOpen}>
                    <SelectInstructorList type={"writer"} showImage={false} notationId={null} OnSelect={(instructor) => { handleInstructorSelect('writer', instructor); setWriterModalIsOpen(false); }} />
                </CustomModal>
            )}
        </>
    );
}

export default Notations