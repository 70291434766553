import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Edit } from "@mui/icons-material";
import Done from "view/cms/components/shared/Done";
import Error from "view/cms/components/shared/Error";
import LoadingInside from "view/cms/components/shared/LoadingInside";
import { BaseHttpService } from "services/base";
import ValuesWidgets from "view/cms/components/forms/shared/ValuesWidgets";
import NamesWidgets from "view/cms/components/forms/shared/NamesWidgets";
import DictionaryNamesWidgets from "view/cms/components/forms/shared/DictionaryNamesWidgets";

function DictionaryEntryForm({ id, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [item_image, setImage] = useState(null);
  const [data, setData] = useState(null);
  const [names, setNames] = useState([]);
  const [namesRefresh, setNamesRefresh] = useState(0);


  const fileRef = useRef()

  useEffect(() => {
    if (id) {
      BaseHttpService.loadDictionaryEntry(id).then(item => {
        setData(item)
        onEditing(item)
      })
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)


    for (const key in item) {
      setValue(key, item[key])
    }

    setNames(item.names)

    setNamesRefresh(refresh + 1)
  }

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;

    var body = {
      "id": id ? id : data.id,
      "key": data.key ?? "",
      "names": names,
    }


    setDone(false)
    setLoading(true)
    BaseHttpService.saveDictionaryEntry(body).then(response => {
      setLoading(false)
      setDone(true)
      setError(false)
      props.onEditDone(response);
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };


  return (
    <div>


      <form className="mt-4" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-12">
            {/* <div className="form-group">
              <TextField
                fullWidth
                label="Key"
                value={watch('key') ?? ""}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("key")}
              />
            </div>
            <hr className="solid" /> */}
            <div className="form-group">
              <DictionaryNamesWidgets key={namesRefresh + "nms"} names={names} setNames={(names) => { console.log(names); setNames(names) }} />
            </div>
            <hr className="solid" />
          </div>
        </div>
        <div className="form-group mt-3 text-right mb-1">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={editingItem ? <Edit /> : <AddIcon />}
          >
            {editingItem ? "Save Changes" : "Add Dictionary Entry"}
          </Button>


        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <LoadingInside />}
        </div>
      </form>
    </div>
  );
}

export default DictionaryEntryForm;
