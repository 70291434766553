import React, { useContext } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import { CourseHttpService } from '../../../../services/course';
import { translate, Utils } from '../../../../utils';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { CheckCircleOutline, AutoStoriesOutlined } from '@mui/icons-material';
function ContentsLightList({ lesson, course , expandParentAccordion=null,lecture_index }) {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const { content } = useParams()



    useEffect(() => {

        _getData()

        return () => {
        }
    }, [])

    const _getData = () => {
        setLoading(true);
        CourseHttpService.loadContents(lesson).then((items) => {
            if (items.results) {
                setData(items.results)

                // _checkLessoonAccordingIsOpen(items.results)

            }
        });
    }


    // const _checkLessoonAccordingIsOpen = (contents) => {
        
    //     const allContentsInSameLesson = contents.every(content => content.lesson === lesson);

    //     if (allContentsInSameLesson && expandParentAccordion) {
    //         expandParentAccordion();
    //     }
    // }




    return (
        <div className='box'>

            <div className="box-body ">
                <div >
                    <div>
                        {data?.map(item => {
                            let isDone = (item?.course_logs && item?.course_logs.length > 0) && item?.course_logs[0].ended != null

                            return (

                                <Link to={`/course/${course}/lesson/${lesson}/content/${item.id}`} key={`content_light_${item.id}`} className={`no-decoration content-link ${content == item.id ? "active" : ""} `} >
                                    <div className={`content-item content-item-light ${content == item.id ? "active" : ""} `} key={`ccontent${item.id}`}>
                                        <div className={`icon ${isDone ? "success" : ""}`}>
                                            {isDone ?
                                                (
                                                    <CheckCircleOutline />

                                                ) : item.url ? (
                                                    <img src="/assets/images/svg/play-circle.svg" width="28" alt="" />
                                                ) : (<img src="/assets/images/svg/book.svg" width="28" alt="" />)}

                                        </div>
                                        <div className="description">
                                            {/* <span className='content-type'>{item.type ?? "Reading"} : </span> */}
                                            {Utils.getDefaultName(item)}
                                            {/* <div className="info">
                                            <div className='content-length'>{item.type}</div>
                                                <span aria-hidden="true" >•</span>

                                                <div className='content-length'>{item.duration} min</div>
                                            </div> */}

                                        </div>
                                    </div>
                                </Link>
                            )
                        }
                        )}
                    </div>
                </div>
            </div>


        </div>
    )
}

export default ContentsLightList