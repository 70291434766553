import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, TextareaAutosize } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Edit } from "@mui/icons-material";
import Done from "view/cms/components/shared/Done";
import Error from "view/cms/components/shared/Error";
import LoadingInside from "view/cms/components/shared/LoadingInside";

import SelectLanguage from "./SelectLanguage";
import { CourseHttpService } from "services/course";
// import MWEditor from "view/cms/components/Widgets/editor/CKEditor";

function LrcForm({ id, videoUrl, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [lrc, setLrc] = useState(null);

  useEffect(() => {
    if (id) {
      CourseHttpService.loadLrc(id).then(item => {
        setLrc(item)
        onEditing(item)
      })
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)


    for (const key in item) {
      setValue(key, item[key])
    }

    if (item.language) {
      setValue("language", item.language.id)
    }
  }

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;

    var body = {
      "id": id ? id : data.id,
      "content": lrc.content,
      "lrc_text": data.lrc_text,
      "language": data.language,
    }



    setDone(false)
    setLoading(true)
    CourseHttpService.saveLrc(body).then(response => {
      console.log(response)
      setLoading(false)
      setDone(true)
      setError(false)
      props.onEditDone(response);
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };

  const handleValue = (value, name) => {
    switch (name) {
      case "language":
        setValue("language", value)
        break;
      default:
        break;
    }
  }



  return (
    <div>


      <form className="mt-4" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group" style={{maxHeight:300, overflow:"auto"}}>
              <TextareaAutosize
                className="full-width"
                label="lrc_text"
                value={watch('lrc_text') ?? ""}
                {...register("lrc_text")}
              />
            </div>

          </div>

          <div className="col-md-6">
            <video style={{ width: "100%" }} controls >
              <source src={videoUrl} type="video/mp4" />
            </video>
            <div className="form-group">
              <SelectLanguage placeholder="Language" width={250} callback={handleValue} value={watch("language")} border={"1"} />
            </div>
            <div className="form-group mt-3 text-right mb-1">
              <Button
                onClick={handleSubmit}
                type="submit"
                variant="contained"
                startIcon={editingItem ? <Edit /> : <AddIcon />}
              >
                {editingItem ? "Save Changes" : "Add Post"}
              </Button>


            </div>
            <div className="form-group">
              {done && <Done />}
              {error && <Error message={error} />}
              {loading && <LoadingInside />}
            </div>
          </div>

        </div>

      </form>
    </div>
  );
}

export default LrcForm;
