import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { CourseHttpService } from 'services/course';
import { Utils } from 'utils';

function NotationsGrid({ query, size = 28, excludeId = null }) {
    const [notations, setNotations] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const { isLoading, error, data, refetch } = useQuery(['notations', pageNumber], () => {
        return CourseHttpService.searchNotations(query, pageNumber, size);
    }, {
        onSuccess: (data) => {
            setNotations(data.results);
            setTotalPages(Math.ceil(data.count / size));
        },
        refetchInterval: 600 * 100000,
    });

    useEffect(() => {
        refetch();
    }, [query, pageNumber]);

    const handlePageChange = (newPageNumber) => {
        setPageNumber(newPageNumber);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    };

    return (
        <div className='mt-1 container notation-grid-container'>
            <div className="row portfolio-list sort-destination lan-rtl">
                {notations?.map((notation, index) => (
                    <div key={notation.id + "ngi"} className="col-sm-6 col-lg-3 isotope-item brands">
                        <div className="portfolio-item">
                            <Link to={`/notation/${notation.id}`}>
                                <span className="thumb-info thumb-info-lighten border-radius-0 kfont lan-rtl">
                                    <span className="thumb-info-wrapper border-radius-0">
                                        <img src={notation.image} className="img-fluid border-radius-0 notation-grid-img" alt="" />
                                        <span className="thumb-info-title">
                                            <span className="thumb-info-inner">{Utils.getDefaultName(notation)}</span>
                                            <span className="thumb-info-type">{notation?.level && Utils.getDefaultName(notation?.level)}</span>
                                        </span>
                                        <span className="thumb-info-action">
                                            <span className="thumb-info-action-icon bg-dark opacity-8"><i className="fas fa-play"></i></span>
                                        </span>
                                    </span>
                                </span>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>

            {size > 10 && (

                <ul className="pagination float-end">
                    <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(pageNumber - 1)} disabled={pageNumber === 1}>
                            <i className="fas fa-angle-left"></i>
                        </button>
                    </li>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <li key={index} className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                {index + 1}
                            </button>
                        </li>
                    ))}
                    <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => handlePageChange(pageNumber + 1)} disabled={pageNumber === totalPages}>
                            <i className="fas fa-angle-right"></i>
                        </button>
                    </li>
                </ul>

            )}
        </div>
    );
}

export default NotationsGrid;
