import React, { Suspense, lazy, useEffect } from 'react'
import { useQuery } from 'react-query';
import { CourseHttpService } from 'services/course';
import { Utils } from 'utils';
import CourseCard from './CourseCard';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { HttpService } from 'services/http';

// const CourseCard = lazy(() => import('./CourseCard'));


function InstructorsGrid({ query }) {

    const [instructors, setInstructors] = useState(null)

    const { isLoading, error, data, refetch } = useQuery('search_instructors', () => { return CourseHttpService.searchInstructors(query) }, {
        onSuccess: (data) => {
            setInstructors(data.results)
        },
        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
        refetchInterval: HttpService.DefaultRefetchInterval,
    });

    useEffect(() => {
        refetch()
    }, [query])

    return (
        <div className='mt-1'>
            <div className="row py-3 ">
                {instructors?.map((instructor, i) => (
                    <div className="col-6 col-md-2 mb-4 mb-md-0 " data-appear-animation="fadeIn" data-appear-animation-delay={i * 250} key={"ins" + instructor.id}>
                        <Link to={`/instructor/${instructor.id}`}>
                            <div className="instructor-grid-item text-center">
                                <img className="img-fluid img-sm-85p mb-4" src={instructor.image} alt={Utils.getDefaultName(instructor)} />
                                <h3 className="kfont text-color-dark text-4 mb-0 ">{Utils.getDefaultName(instructor)}</h3>

                            </div>
                        </Link>

                    </div>
                ))}

            </div>
        </div>
    )
}

export default InstructorsGrid