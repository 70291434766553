import React from 'react';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from "react-router-dom";
import { CourseHttpService } from '../../../../services/course';
import { Utils } from '../../../../utils';
import CustomModal from '../../../components/modals/CustomModal';
import InstructorForm from '../forms/course/InstructorForm';
import DeleteDialog from '../shared/DeleteDialog';
import SortModal from '../Widgets/shared/sort/SortModal';
import { DragHandle } from '@mui/icons-material';
import SearchQuery from '../forms/shared/SearchQuery';
import { Avatar } from '@mui/material';
import TranslateController from 'controller/shared/TranslateController';

function InstructorList({ }) {

  const [items, setData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState(''); // State to manage active tab

  const { isLoading, error, data, refetch } = useQuery(['instructors', searchQuery], () => { 
    return CourseHttpService.searchInstructors(searchQuery); 
  }, {
    onSuccess: (data) => {
      setData(data.results)
    }
  });

  useEffect(() => {
    refetch()
    return () => {
    }
  }, [searchQuery])

  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [sortModalIsOpen, setSortModalIsOpen] = useState(false);

  const [selectedId, setSelectedId] = useState(0);
  const { id } = useParams();
  const [refresh, setRefresh] = useState(0);

  const {_t} = TranslateController();

  const _handleDelete = (e, id) => {
    setSelectedId(id);
    setRefresh(refresh + 1)
    setDeleteDialogIsOpen(true)
  }

  const _onDeleteDone = () => {
    var temp = items.filter(item => item.id !== selectedId)
    setData(temp)
    setDeleteDialogIsOpen(false);
    setRefresh(refresh + 1)
  }

  const _edit = (e, item) => {
    e.preventDefault();
    setSelectedId(item.id);
    setEditModalIsOpen(true)
    setRefresh(refresh + 1)
  }

  const handleTabClick = (type) => {
    setActiveTab(type);
    setSearchQuery(`&type=${type}`);
  }

  const types = [
    { label: "Author", value: "author" },
    { label: "Course Instructor", value: "instructor" },
    { label: "Artist", value: "artist" },
    { label: "Transcriber", value: "transcriber" },
    { label: "Podcast Writer", value: "writer" },
    { label: "Narrator", value: "narrator" },
];

  return (
    <div className='box'>
      <div className="box-header mb-5">
        <h5>People</h5>
        <div className="btns box-header-btns">
          <SearchQuery onQuery={(query) => {
            if(activeTab){
              query += `&type=${activeTab}`;
            }
            setSearchQuery(query);
          }} query={searchQuery} />
          <button className="btn btn-icon" title="Sort courses" onClick={() => { setSelectedId(null); setSortModalIsOpen(true) }}><DragHandle /></button>
          <button className="btn btn-icon" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus "></i></button>
        </div>
      </div>

      <div className="tabs tabs-top mt-3 full-width">
        <ul className="nav nav-tabs nav-tabs-custom">
          {types.map((type, index) =>(
            <li className="nav-item" key={`type` + index}>
              <a className={`nav-link ${activeTab === type.value ? 'active' : ''}`} href={`#${type.value}`} data-bs-toggle="tab" role="tab" onClick={() => handleTabClick(type.value)}>{_t(type.label)}</a>
            </li>
          ))}
          
        </ul>
      </div>

      <div className="box-body d-flex flex-wrap">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th></th>
              <th>Name</th>
              {/* <th className='hide-on-small'>Type</th> */}
              <th className='hide-on-small'>Date </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items && items.map(item => (
              <tr key={"people_item_"+item.id}>
                <td>{item.id}</td>
                <td><Link onClick={(e) => { _edit(e, item) }}><Avatar alt={Utils.getDefaultName(item)} src={Utils.getUserImage(item)} /></Link></td>
                <td className='kfont'>
                  <Link onClick={(e) => { _edit(e, item) }}>
                    {Utils.getDefaultName(item)}
                  </Link>
                </td>
                {/* <td className=''>{item.type}</td> */}
                <td className='hide-on-small'>{Utils.getDate(item.created_at)} <br /> <small title='Last login'>{item.last_login && Utils.getDate(item.last_login)}</small> </td>
                <td className='text-right'>
                    <a href="#" onClick={(e) => { _handleDelete(e, item.id) }} className="btn btn-outline btn-swap-1">
                      <span><i className="fas fa-trash color-gray "></i></span>
                      <span>Delete <i className="fas fa-trash ms-2 color-gray "></i></span>
                    </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <CustomModal className="" big key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)} open={editModalIsOpen}>
        <InstructorForm id={selectedId} />
      </CustomModal>

      <DeleteDialog
        url={`/academy/course/instructor/${selectedId}`}
        isOpen={deleteDialogIsOpen}
        key={"mdl" + deleteDialogIsOpen + selectedId}
        setOpen={setDeleteDialogIsOpen}
        notifyDone={_onDeleteDone}
      />

      <SortModal type="instructor" data={items} setData={setData} isOpen={sortModalIsOpen} setIsOpen={setSortModalIsOpen} />
    </div>
  )
}

export default InstructorList;
