import React from 'react';
import { TextField } from '@mui/material';

function UploadVideoTextField({ register, value, label = "" }) {
    return (
        <TextField
            fullWidth
            label={label}
            value={value}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            {...register}
        />
    );
}

export default UploadVideoTextField;
