import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { Utils } from 'utils';
import { PostHttpService } from 'view/cms/features/feature_blog/services';

function HeaderCategories() {

    const [categories, setCategories] = useState(null);

    const { isLoading, error, data, refetch } = useQuery('categories', () => { return PostHttpService.loadCategories(null, 0) }, {
        onSuccess: (data) => {
            setCategories(BuildCategories(data))
        },
        refetchInterval: 60 * 10000,

        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
    });


    const getChilds = (data, parentId, active_only) => {
        var childs = [];
        if (active_only)
            childs = data.filter(x => x.parent?.id === parentId && x.status);
        else
            childs = data.filter(x => x.parent?.id === parentId);
        childs.forEach(item => {
            item.childs = getChilds(data, item.id, active_only)
        })
        return childs;

    }


    const BuildCategories = (categories, active_only) => {

        if (categories != null) {

            var parents = [];

            if (active_only)
                parents = categories?.filter(x => !x.parent && x.status);
            else
                parents = categories?.filter(x => !x.parent);

            parents.forEach(parent => {
                parent.childs = getChilds(categories, parent.id, active_only)
            })

            return parents;
        }

    }


    return (
        <ul className="dropdown-menu">
            {categories && categories.map((category) =>
                <CategoryMenu key={"catm" + category.id} category={category} />
            )}
        </ul>

    )
}

function CategoryMenu({ category }) {

    const [open, setOpen] = useState(false)

    return (category.childs && category.childs.length > 0) ? (
        <li key={"catheader" + category.id} className={`dropdown-submenu   kfont ${open ? "open" : ""} `}>
            <a className="dropdown-item " href={`/articles/${category.id}`}>{Utils.getDefaultName(category)}
                <i className="fas fa-chevron-down" onClick={(e) => { e.preventDefault(); setOpen(!open) }}></i>
            </a>
            <ul className="dropdown-menu">
                {category.childs.map(child =>
                    <CategoryMenu key={"child" + child.id} category={child} />
                )}
            </ul>
        </li>
    ) : (
        <li key={"catheader" + category.id} className="kfont">
            <a className="dropdown-item" href={`/articles/${category.id}`}>
                {Utils.getDefaultName(category)}
            </a>
        </li>
    );
}

export default HeaderCategories