import React, { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { CourseHttpService } from 'services/course';
import { useQuery } from 'react-query';
import { HttpService } from 'services/http';

const GenderStatisticsChart = () => {

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

    const { isLoading, error, data, refetch } = useQuery('statistics_gender', () => { return CourseHttpService.loadGenderStatistics() }, {
        onSuccess: (data) => {
            const genderLabels = data.map((item) => item.gender);
            const userCounts = data.map((item) => item.user_count);

            const barColors = [
                'rgba(255,0 , 0, 0.6)', // Color for the first bar
                'rgba(75, 192, 192, 0.6)', // Color for the second bar
                'rgba(255, 206, 86, 0.6)', // Color for the third bar
                'rgba(153, 255, 102, 0.6)', // Color for the fourth bar
                // Add more colors if you have more groups
            ];


            setChartData({
                labels: genderLabels,
                datasets: [
                    {
                        label: 'User Count by Gender',
                        data: userCounts,
                        backgroundColor: [
                            'rgba(54, 162, 235, 0.6)',  // Male
                            'rgba(255, 99, 132, 0.6)',  // Female
                            'rgba(255, 206, 86, 0.6)',  // Other
                        ],
                        borderColor: [
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 99, 132, 1)',
                            'rgba(255, 206, 86, 1)',
                        ],
                        borderWidth: 1,
                    },
                ],
            });
        },
        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
        refetchInterval: HttpService.DefaultRefetchInterval,
    });




    return (
        <div className="">
            <Pie
                data={chartData}
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            display: true,
                            position: 'bottom',
                            labels: {
                                padding: 30,
                            },
                        },
                    },
                }}
            />
        </div>

    );
};

export default GenderStatisticsChart;
