import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { CourseHttpService } from '../../../../services/course';
import { Utils } from '../../../../utils';

function SelectInstructorList({courseId,podcastId,OnSelect,showImage=true,type="instructor"}) {

useEffect(() => {

  _getData()

  return () => {
  }
}, [])

const _getData = () => {

  if(podcastId){
    CourseHttpService.loadInstructorsForPodcast({exclude_podcast : podcastId,type:type}).then((items) => {
      if (items.results) {
        setData(items.results)
      }
    });
  }
  else{
    CourseHttpService.loadInstructors(null,courseId,null,type).then((items) => {
      if (items.results) {
        setData(items.results)
      }
    });
  }
  
}


  const [data, setData] = useState(null);

  const _select = (e, item) => {
    e.preventDefault();
    setData()
    OnSelect(item);
  }



  return (
    <div className='box'>
      <div className="box-body general-list">
        {data?.map(item => (
          <div key={item.id + "cmp"} className='general-list-item' onClick={(e) => { _select(e, item) }}>
            {showImage && (<img className="img-fluid mb-4 max-width-100" src={Utils.getImage(item)} alt=""></img>)}
            <div className="caption">
              <h3 className='mb-0'> {Utils.getDefaultName(item)}</h3>
            </div>
          </div>
        )
        )}
      </div>

    </div>
  )
}

export default SelectInstructorList