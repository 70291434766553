import React, { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { CourseHttpService } from "../../../../../services/course";
import Done from "../../shared/Done";
import Error from "../../shared/Error";
import Loading from "../../shared/Loading";
import { Edit } from "@mui/icons-material";
import NamesWidgets from "../shared/NamesWidgets";
import Gallery from "../../Widgets/shared/Gallery";
import ImageFieldWithGallery from "../../Widgets/shared/ImageFieldWithGallery";
import SelectLevel from "./selects/SelectLevel";
import SelectCategory from "view/cms/features/feature_blog/components/forms/SelectCategory";
import UploadVideoTextField from "../../Widgets/shared/UploadVideoTextField";
import CustomModal from "view/components/modals/CustomModal";
import UploadToAmazon from "../../Widgets/shared/UploadToAmazon";

function CourseForm({ id, week, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [namesRefresh, setNamesRefresh] = useState(0);
  const [course_image, setCourseImage] = useState(null);
  const [names, setNames] = useState([]);
  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);


  useEffect(() => {
    if (id) {
      CourseHttpService.loadCourse(id).then(item => {
        onEditing(item)
      })
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)

    for (const key in item) {
      setValue(key, item[key])
    }

    if (item.category) {
      setValue("category", item.category?.id)
    }


    if (item.level) {
      setValue("level", item.level?.id)
    }

    setNames(item.names)
    setNamesRefresh(namesRefresh + 1)
    setCourseImage(item.image)

  }

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;



    var body = {
      "id": id ? id : data.id,
      names: names,
      "week": week,
      "level": data.level,
      "category": data.category,
      "image": course_image,
      "intro_url": data.intro_url
    }


    setDone(false)
    setLoading(true)
    CourseHttpService.saveCourse(body).then(response => {
      console.log(response)

      setLoading(false)
      setDone(true)
      setError(false)
      props.onEditDone(response);
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };


  const handleValue = (value, name) => {
    switch (name) {

      case "category":
        setValue("category", value)
        break;
      case "level":
        setValue("level", value)
        break;
      default:
        break;
    }
  }



  return (
    <div>


      <form className="" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              <NamesWidgets show_short_description={1} key={namesRefresh + "nms"} names={names} setNames={(names) => { setNames(names) }} />
            </div>
            <div className="form-group d-flex">
              <TextField
                fullWidth
                label="Introduction Video Url"
                disabled
                value={watch('intro_url') ?? ""}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("intro_url")}
              />
              <button type="button" className="btn btn-primary ml-2" onClick={() => { setUploadModalIsOpen(true) }}><i className="fa fa-upload"></i></button>

            </div>

          </div>
          <div className="col-md-4">
            <div className="form-group">
              <ImageFieldWithGallery onImageSelected={setCourseImage} defaultImageUrl={course_image} key={course_image + "imgg"} />
            </div>
            <div className="form-group">
              <SelectCategory width={"100%"} name="category" placeholder="Categoty" callback={handleValue} value={watch("category")} border={"1"} />
            </div>
            <div className="form-group">
              <SelectLevel width={"100%"} callback={handleValue} value={watch("level")} border={"1"} />
            </div>
          </div>
        </div>
        <div className="form-group mt-3 text-right mb-1">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={editingItem ? <Edit /> : <AddIcon />}
          >
            {editingItem ? "Save Changes" : "Add Course"}
          </Button>


        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <Loading />}
        </div>
      </form>

      <CustomModal className="" big key={uploadModalIsOpen} setOpen={(value) => setUploadModalIsOpen(value)}
        open={uploadModalIsOpen} >

        <UploadToAmazon
          uploadFolder={`courses/intro`}
          file_type="video/*"
          onEditDone={(url) => {
            setValue('intro_url', url)
            setTimeout(() => {
              setUploadModalIsOpen(false)
            }, 1000)
          }}
        />

      </CustomModal>
    </div>
  );
}

export default CourseForm;
