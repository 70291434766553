import React, { useContext, useEffect, useRef, useState } from 'react'
import { Utils, translate } from '../../../utils'
import PageLayout from 'view/components/shared/PageLayout'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { CourseHttpService } from 'services/course'
import PodcastSummary from 'view/components/course/components/PodcastSummary'
import BottomSoundPlayer from 'view/features/feature_player/components/BottomSoundPlayer'
import { AccessTimeOutlined, ShareOutlined } from '@mui/icons-material'
import ReactAudioPlayer from 'react-audio-player';
import TranslateController from 'controller/shared/TranslateController'
import { HttpService } from 'services/http'
import ShareUrl from 'view/components/shared/ShareUrl'
import CustomModal from 'view/components/modals/CustomModal'

function Podcast() {
	const { id , episodeIndex } = useParams();

	const {_t} = TranslateController();


	const [podcast, setPodcast] = useState([]);
	const [episodes, setEpisodes] = useState([]);
	const [hoveredEpisode, setHoveredEpisode] = useState(null);
	const [playingEpisodeIndex, setPlayingEpisodeIndex] = useState(null);
	const [playingEpisode, setPlayingEpisode] = useState(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [currentTrack, setCurrentTrack] = useState(null);
	const [currentTime, setCurrentTime] = useState(0);
	const [totalTime, setTotalTime] = useState(0);
	const [playingShuffle, setPlayingShuffle] = useState(false);
	const [shareEpisode, setShareEpisode] = useState(null);
	const [sharedIndex, setSharedIndex] = useState(1);
	
	const { isLoading, error, data } = useQuery(['podcast', id], () => { return CourseHttpService.loadPodcast(id) }, {
		onSuccess: (podcast) => {
			if(podcast){
				setPodcast(podcast);
				setEpisodes(podcast.podcastepisodes)
				// if(episodeIndex){
				// 	_playEpisode(episodeIndex);
				// }
			}
		},
		staleTime: Infinity,
		cacheTime: 1000 * 60 * 60 * 24,
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		// Utils.initiateTheme()
	}, [])

	useEffect(() => {
		if (podcast) {
		  // Play the specific episode based on the episodeIndex
		  _playEpisode(episodeIndex-1)
		}
	  }, [podcast, episodeIndex]);

	



	const audioRef = useRef();

	const handleMouseEnter = (index) => {
		setHoveredEpisode(index);
	};

	const handleMouseLeave = () => {
		setHoveredEpisode(null);
	};

	const handlePlayPauseClick = (index) => {
		if (playingEpisodeIndex === index) {
			if (isPlaying) {
				setIsPlaying(false);
				audioRef.current?.audioEl.current.pause();
			} else {
				setIsPlaying(true);
				audioRef.current?.audioEl.current.play();
			}
		} else {
			_playEpisode(index);
		}
	};

	const _playEpisode = (index) => {

		if(episodes.length==0) return;
		setPlayingEpisodeIndex(index);
		var episode = episodes[index];

		if(episode){
			setPlayingEpisode(episode);

			_updateEpissodeCounts(episode.id);
			setCurrentTrack(episode?.url ?? "")
			// setIsPlaying(true);
			// audioRef.current?.audioEl.current.play();
		}
	}

	const _updateEpissodeCounts = (episodeId) => {

		CourseHttpService.updateEpisodeCount(episodeId);
	}

	const handlePlayPause = () => {

		if(currentTrack == null){
			handlePlayPauseClick(0)
		}
		else {
			if (isPlaying) {
				audioRef.current.audioEl.current.pause();
				setIsPlaying(false);
			} else {
				audioRef.current.audioEl.current.play();
				setIsPlaying(true);
			}
		}
		
	};

	useEffect(() => {
		if (audioRef.current) {
			const handleLoadedMetadata = () => {
				setTotalTime(audioRef.current.audioEl.current.duration);
			};

			const handleTimeUpdate = () => {
				setCurrentTime(audioRef.current.audioEl.current.currentTime);
			};

			audioRef.current?.audioEl.current.addEventListener('loadedmetadata', handleLoadedMetadata);
			audioRef.current?.audioEl.current.addEventListener('timeupdate', handleTimeUpdate);

			return () => {
				audioRef.current?.audioEl.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
				audioRef.current?.audioEl.current.removeEventListener('timeupdate', handleTimeUpdate);
			};
		}
	}, [currentTrack]);

	const handlePlayNext = () => {

		if(playingShuffle){
			let randomIndex = Math.floor(Math.random() * episodes.length);
			if(randomIndex == playingEpisodeIndex)
				randomIndex = 0;

			handlePlayPauseClick(randomIndex);
		}
		else if (playingEpisodeIndex < episodes.length - 1) {
			handlePlayPauseClick(playingEpisodeIndex + 1);
		}
	};

	const handlePlayPrevious = () => {

		if(playingShuffle){
			let randomIndex = Math.floor(Math.random() * episodes.length);
			if(randomIndex == playingEpisodeIndex)
				randomIndex = episodes.length-1;

			handlePlayPauseClick(randomIndex);
		}
		else if (playingEpisodeIndex > 0) {
			handlePlayPauseClick(playingEpisodeIndex - 1);
		}
	};

	const handleShuffle = () => {
		setPlayingShuffle(!playingShuffle);
	};

	const handleRepeat = () => {
		if (audioRef.current && audioRef.current.audioEl.current) {

			audioRef.current.audioEl.current.currentTime = 0;
			audioRef.current.audioEl.current.play();
		}
	};

	const handleSeek = (newTime) => {
		if (audioRef.current && audioRef.current.audioEl.current) {
			audioRef.current.audioEl.current.currentTime = newTime;
			setCurrentTime(newTime);
		}
	};

	

	const handleSharePodcast = (e,index,episode) => {
		e.preventDefault();
		e.stopPropagation();
		setSharedIndex(index+1)
		setShareEpisode(episode)
	};

	function FirstTd({ index }) {
		if (hoveredEpisode === index) {
			return (
				<>
					{isPlaying && playingEpisodeIndex === index ? (
						<i className="fa fa-pause"></i>
					) : (
						<i className="fa fa-play"></i>
					)}
				</>
			);
		} else {
			return (
				<>
					{isPlaying && playingEpisodeIndex === index ? (
						<img src="/assets/images/playing.gif" />
					) : (
						<>{index + 1}</>
					)}
				</>
			);
		}
	}


	return (
		<PageLayout showbreadcrumbs={false}  HeaderChild={<PodcastSummary key={playingEpisode+"pspe"} playingEpisode={playingEpisode} handlePlayPause={handlePlayPause} isPlaying={isPlaying} podcast={data} />}>

			<ReactAudioPlayer
				src={currentTrack}
				ref={audioRef}
				autoPlay
				onPlay={() => setIsPlaying(true)}
				onPause={() => setIsPlaying(false)}
				onLoadedMetadata={(e) => setTotalTime(e.target.duration)}
				onTimeUpdate={(e) => setCurrentTime(e.target.currentTime)}
			/>
			<table className="table episodes-list-table lan-kfont lan-rtl">
				<thead>
					<tr>
						<th className='hide-on-small' style={{ width: 66 }}>#</th>
						<th className='lan-kfont'>{_t("title")}</th>
						<th className='lan-kfont  hide-on-small'>{_t("plays")}</th>
						<th className="text-center hide-on-small">
							<AccessTimeOutlined />
						</th>
						<th className="text-center" style={{width:"30px"}}></th>
					</tr>
				</thead>
				<tbody className='lan-rtl'>
					{episodes?.map((episode, index) => (
						<tr
							key={"ep" + episode.id}
							title={Utils.getDefaultName(episode)}
							onMouseEnter={() => handleMouseEnter(index)}
							onMouseLeave={handleMouseLeave}
							onClick={() => handlePlayPauseClick(index)}
						>
							<td className='hide-on-small' >
								<FirstTd index={index} />
							</td>
							<td className='d-flex'>
								<img src={episode.image} className="episode-list-image" />
								<div>{Utils.getDefaultName(episode)}</div>
							</td>
							<td>
								{episode.play_count ?? 101}
							</td>
							<td className="text-center hide-on-small">{episode.duration}</td>
							<td title={_t('Share episode')} className="text-center hide-on-small position-relative " onClick={(e)=>{ handleSharePodcast(e,index,episode) }}><ShareOutlined />
								
							</td>
						</tr>
					))}
					
				</tbody>
			</table>

			{shareEpisode && (
				<CustomModal dark medium className="p-4" key={shareEpisode} setOpen={(value) => setShareEpisode(value)}
				open={shareEpisode} >
					<ShareUrl title={`Listen to ${Utils.getDefaultName(shareEpisode)} on kurdish Academy`} url={HttpService.SiteBase+`/podcast/${id}/${sharedIndex}`} />
			  </CustomModal>
			)}
			
			<BottomSoundPlayer
				isPlaying={isPlaying}
				currentTrack={currentTrack}
				totalTime={totalTime}
				currentTime={currentTime}
				Playnext={handlePlayNext}
				PlayPrevious={handlePlayPrevious}
				Repeat={handleRepeat}
				handleShuffle={handleShuffle}
				playingShuffle={playingShuffle}
				handlePlayPause={handlePlayPause}
				onSeek={handleSeek}

			/>
		</PageLayout>


	)
}

export default Podcast