import React, { useState } from 'react'
import { Utils } from 'utils';
import { useQuery } from 'react-query';
import CustomModal from 'view/components/modals/CustomModal';
import DeleteDialog from 'view/cms/components/shared/DeleteDialog';
import { CourseHttpService } from 'services/course';
import LrcForm from 'view/cms/features/feature_blog/components/forms/LrcForm';

function LrcList({contentId,videoUrl}) {
    const [lyrics, setData] = useState(null);
    const [refresh, setRefresh] = useState(0);

    const { isLoading, error, data, refetch } = useQuery(['lrcs',contentId], ()=>{ return CourseHttpService.loadAllLrcs(contentId) }, {
        onSuccess: (data) => {
            setData(data.results)
        },
        refetchInterval: 60 * 1000000,
    });

    const [selectedId, setSelectedId] = useState(0);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);



    const _handleEdit = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1)
        setEditModalIsOpen(true)
    }

    const _handleDelete = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1)
        setDeleteDialogIsOpen(true)
    }

    const _onDeleteDone = () => {
        var temp = lyrics.filter(item => item.id !== selectedId)
        setData(temp)
        setDeleteDialogIsOpen(false);
        setRefresh(refresh + 1)
    }

    return (
        <div className='box'>
            <div className="box-header mb-5">
                <h5>Lyrics</h5>
                {/* <div className="btns d-flex">
                    <button className="btn btn-icon bg-success" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus "></i></button>
                </div> */}
            </div>
            <div className="box-body general-list">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th>Language</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {lyrics && lyrics.map(item => (
                            <tr key={"cat" + item.id}>
                                <td>{item.id}</td>
                                <td className='kfont'>{item.language?.name}</td>
                                <td className='text-right'>
                                <a href="#" onClick={(e)=>{ e.preventDefault(); setSelectedId(item.id); setEditModalIsOpen(true); }} className="btn  btn-primary btn-swap-1 mr-2">
                                    <span><i className="fas fa-edit"></i></span>
                                    <span>Edit <i className="fas fa-edit ms-2"></i></span>
                                </a>
                                <a href="#" onClick={(e) => { _handleDelete(e, item.id) }} className="btn btn-outline btn-swap-1">
                                    <span><i className="fas fa-trash color-gray "></i></span>
                                    <span>Delete <i className="fas fa-trash ms-2 color-gray "></i></span>
                                </a>

                            </td>

                            </tr>
                        ))}
                        

                    </tbody>


                </table>
            </div>

            <CustomModal big key={editModalIsOpen} setOpen={(value)=>{setEditModalIsOpen(value)}}
                open={editModalIsOpen} >

                <LrcForm id={selectedId} videoUrl={videoUrl} onEditDone={() => { refetch(); setEditModalIsOpen(false) }} />

            </CustomModal>
           
            {/* <PostForm id={selectedId} onEditDone={() => { refetch(); setEditModalIsOpen(false) }} /> */}

            <DeleteDialog
                url={`/academy/course/lrc/${selectedId}`}
                isOpen={deleteDialogIsOpen}
                key={"mdl" + deleteDialogIsOpen + selectedId}
                setOpen={setDeleteDialogIsOpen}
                notifyDone={_onDeleteDone}
            />
        </div>
    )
}

export default LrcList