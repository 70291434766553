import TranslateController from 'controller/shared/TranslateController';
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import CustomModal from 'view/components/modals/CustomModal'

function HomeTutorialsCarousel({  }) {


    const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);
    const {_t} = TranslateController();


    var settings = {
        dots: false,
        autoplay: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    };


    return (
        <div className='carousel-container ' >
            <Slider    {...settings}
            >
                <div class="portfolio-item p-3 " onClick={(e) => { e.preventDefault(); setVideoModalIsOpen("p1") }}>
                    <a href="#">
                        <span class="thumb-info thumb-info-lighten border-radius-0">
                            <span class="thumb-info-wrapper border-radius-0">
                                <img src={"/assets/video/tutorial/p1.jpg"} className='full-width pointer' />
                                <span class="thumb-info-title">
                                    <span class="thumb-info-inner kfont">{_t("Promo 1")}</span>
                                </span>
                                <span class="thumb-info-action">
                                    <span class="thumb-info-action-icon bg-dark opacity-8"><i class="fas fa-play"></i></span>
                                </span>
                            </span>
                        </span>
                    </a>
                </div>
                <div class="portfolio-item p-3" onClick={(e) => { e.preventDefault(); setVideoModalIsOpen("p2") }}>
                    <a href="#">
                        <span class="thumb-info thumb-info-lighten border-radius-0">
                            <span class="thumb-info-wrapper border-radius-0">
                                <img src={"/assets/video/tutorial/p2.jpg"} className='full-width pointer' />
                                <span class="thumb-info-title">
                                    <span class="thumb-info-inner kfont">{_t("Promo 2")}</span>
                                </span>
                                <span class="thumb-info-action">
                                    <span class="thumb-info-action-icon bg-dark opacity-8"><i class="fas fa-play"></i></span>
                                </span>
                            </span>
                        </span>
                    </a>
                </div>
                <div class="portfolio-item p-3" onClick={(e) => { e.preventDefault(); setVideoModalIsOpen("p3") }}>
                    <a href="#">
                        <span class="thumb-info thumb-info-lighten border-radius-0">
                            <span class="thumb-info-wrapper border-radius-0">
                                <img src={"/assets/video/tutorial/p3.jpg"} className='full-width pointer' />
                                <span class="thumb-info-title">
                                    <span class="thumb-info-inner kfont">{_t("Promo 3")}</span>
                                </span>
                                <span class="thumb-info-action">
                                    <span class="thumb-info-action-icon bg-dark opacity-8"><i class="fas fa-play"></i></span>
                                </span>
                            </span>
                        </span>
                    </a>
                </div>
                <div class="portfolio-item p-3" onClick={(e) => { e.preventDefault(); setVideoModalIsOpen("p4") }}>
                    <a href="#">
                        <span class="thumb-info thumb-info-lighten border-radius-0">
                            <span class="thumb-info-wrapper border-radius-0">
                                <img src={"/assets/video/tutorial/p4.jpg"} className='full-width pointer' />
                                <span class="thumb-info-title">
                                    <span class="thumb-info-inner kfont">{_t("Promo 4")}</span>
                                </span>
                                <span class="thumb-info-action">
                                    <span class="thumb-info-action-icon bg-dark opacity-8"><i class="fas fa-play"></i></span>
                                </span>
                            </span>
                        </span>
                    </a>
                </div>
            </Slider>

            <CustomModal className="p-0" padding={0} dark big key={videoModalIsOpen} setOpen={(value) => setVideoModalIsOpen(value)}
                open={videoModalIsOpen} >
                <video src={`/assets/video/tutorial/${videoModalIsOpen}.mp4`} poster={`/assets/video/tutorial/${videoModalIsOpen}.jpg`} controls className='full-width' />
            </CustomModal>

        </div>
    )
}

export default HomeTutorialsCarousel