import React, { useEffect, useState } from 'react'
import PageLayout from 'view/components/shared/PageLayout'
import { PostHttpService } from '../../../cms/features/feature_blog/services'
import { Link, useParams } from 'react-router-dom'
import { Utils } from 'utils';
import PostsAside from 'view/features/feature_blog/components/PostsAside';
import TranslateController from 'controller/shared/TranslateController';
import SocialShareButtons from '../components/SocialShareButtons';
import Meta from 'view/components/shared/home/Meta';
import { HttpService } from 'services/http';

function Post() {
    const [post, setData] = useState(null);

    const { id, slug } = useParams();

    const { _t } = TranslateController()

    useEffect(() => {
        if (id) {
            PostHttpService.loadPost(id).then(item => {
                document.title = "Kurdish Academy | " + item.title;
                setData(item)
            })
        }
    }, [id])

    const getHashTags = (post) => {
        const keywords = post?.keywords?.split(',');

        // Prepend each item with a hashtag and join them back into a string
        const hashtags = keywords?.map(item => {
            if (!item) return;
            return (
                <a href={`/keyword/${item.replaceAll(' ', '-')}`} target='_blank'>

                    <span key={'key' + item} className="badge bg-dark--100 font-weight-normal text-2 badge-md anim-hover-translate-top-5px transition-2ms me-2 mb-2">{item}</span>
                </a>
            )
        }
        );

        return hashtags;

    }

    return (
        <PageLayout showbreadcrumbs={false}>

            {post && (
                <Meta title={post.title} description={post.short_description} image={post.image} url={HttpService.SiteBase+"/post/"+post.id} />
            )}

            <div className="container py-4">

                <div className="row lan-rtl">
                    <div className="col-md-8">
                        <div className="blog-posts single-post">
                            {post && (
                                <article className="post post-large blog-single-post border-0 m-0 p-0 lan-rtl">
                                    {/* <div className="post-date ms-0">
                                        <span className="day">{Utils.getDay(post.created_at)}</span>
                                        <span className="month">{Utils.getMonth(post.created_at)}</span>
                                    </div> */}

                                    <div className="post-content ms-0">

                                        <h2 className="font-weight-semi-bold"><Link to={`/post/${post.id}`}>{post.title}</Link></h2>

                                        <div className="post-meta">
                                            {/* <span><i className="far fa-user"></i> By <Link to={`/author/${post.author?.id}/`}>{post.author && Utils.getDefaultName(post.author)}</Link> </span> */}
                                            <span><i className="far fa-folder"></i> <Link to={`/articles/${post.category?.id}/`}>{Utils.getDefaultName(post.category)}</Link> </span>
                                        </div>


                                        <div>
                                            <img src={post.image} className="full-width   mb-4 img-fluid img-thumbnail img-thumbnail-no-borders rounded-0 " alt={post.title} />

                                            <div className="lan-kfont">
                                                {Utils.parse(post.description)}

                                            </div>


                                            <div className="keywords">
                                                {getHashTags(post)}
                                            </div>
                                        </div>
                                        <div className="post-block mt-5 post-share">
                                            <h4 className="mb-3">{_t("Share this Post")}</h4>

                                            <SocialShareButtons title={post && post.title} url={`https://kurdishtalent.academy/post/${post && post.id}`} />
                                        </div>


                                    </div>
                                </article>
                            )}
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                    <PostsAside />
                </div>

            </div>
        </PageLayout>
    )
}

export default Post