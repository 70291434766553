import ContentVideoController from 'controller/course/content/ContentVideoController';
import VideoQuestionIndicators from './question/VideoQuestionIndicators';
import VideoQuestion from './question/VideoQuestion';
import { useEffect } from 'react';
import { Utils } from 'utils';
import NextContent from 'view/components/course/content/NextContent';

const ContentVideo = ({ content, videoUrl, onProgress, poster, currentTime, ...props }) => {

  const { currentSubtitle, videoRef,showNextDialog, handleVideoStart, 
    handleVideoEnd,setShowNextDialog, videoLoaded, question, setQuestion } = ContentVideoController({ content: content, currentTime: currentTime, onProgress: onProgress })


    useEffect(() => {
      const handleContextmenu = e => {
          e.preventDefault()
      }
      document.addEventListener('contextmenu', handleContextmenu)
      return function cleanup() {
          document.removeEventListener('contextmenu', handleContextmenu)
      }
}, [ ])

  return (
    <div className='watch-video-container mb-4 mt-3'>
      <video ref={videoRef}
      controlsList='nodownload' 
      onPlay={handleVideoStart}  poster={poster} onEnded={handleVideoEnd} currenttime={currentTime} controls >
        <source src={videoUrl} type="video/mp4" />
        <button id="customButton">Custom Button</button>
      </video>

      {videoLoaded && (
        <VideoQuestionIndicators videoElement={videoRef.current} content={content} onQuestionClick={setQuestion} />
      )}

      {currentSubtitle && <div className="transcript-on-video">
        {currentSubtitle.text}
      </div>}


      {question && <VideoQuestion question={question} onClose={() => { setQuestion(null); if (videoRef.current) videoRef.current.play() }} />}

      {showNextDialog &&
        <NextContent content={content} onBack={setShowNextDialog} />
      }
    </div>
  );
};

export default ContentVideo;