import { HttpService } from "../services/http";
import Constants from "./constants";
import parse from 'html-react-parser'


export function translate(key, translations) {
    if (!translations) return key;
    if (!key) return "";
    let _language = Utils.getCurrentLanguageId()
    let _translate = translations.find(item => item.key?.toLowerCase() === key?.toLowerCase());


    if (_translate) {
        let _translate_value = _translate.values.find(item => item.language === _language);
        if (_translate_value) {
            return _translate_value.value;
        }

    }


    return key;
}

export class Utils {

    Utils() {

    }


    static SiteTitle = 'Kurdish Academy | ';

    static initialize = function () {
        this.checkSignOutRequired();
    }


    static checkSignOutRequired() {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.has('signout')) {
            Utils.removeUserSession();
        }
    }

    static initiateTheme = function () {
        // First, find and remove the existing script tag if it exists
        const existingScript = document.querySelector('script[src="/assets/js/theme.init.js"]');
        if (existingScript) {
            existingScript.parentNode.removeChild(existingScript);
        }

        // Find and remove the scroll-to-top anchor tag if it exists
        const scrollToTopLink = document.querySelector('a.scroll-to-top');
        if (scrollToTopLink) {
            scrollToTopLink.parentNode.removeChild(scrollToTopLink);
        }

        // Then, create and append a new script tag
        const script = document.createElement("script");
        script.src = "/assets/js/theme.init.js";
        document.body.appendChild(script);
    }

    static scrollTo = function (top = 0) {
        window.scrollTo({
            top: top,
            behavior: 'smooth'
        });
    }

    static redirectParticipant = function () {
        const nextPage = "/home"; // Default page if no previous page is available

        // Check if the previous page was a course page
        const previousPath = window.location.pathname;
        const coursePagePattern = /^\/course\/(\d+)$/; // Assuming course IDs are numeric
        const match = previousPath.match(coursePagePattern);

        if (match) {
            const courseId = match[1];
            // Redirect to the specific course week page
            window.location.href = `/course/${courseId}/lectures`;
        } else {
            // Redirect to the default page
            window.location.href = nextPage;
        }
    }




    static getCurrentLanguageName = function () {
        let _language_name = window.localStorage.getItem(Constants.LanguageTokenName)
        return _language_name ?? "en";
    }

    static isRtl = function () {
        let _language_name = window.localStorage.getItem(Constants.LanguageTokenName)
        return _language_name == 'so' ? true : false;
    }

    static getCurrentLanguageNameFull = function () {
        let _language_name = window.localStorage.getItem(Constants.LanguageTokenName)

        switch (_language_name) {
            case "kr":
                _language_name = "Kurdî"
                break;
            case "so":
                _language_name = "کوردی"
                break;
            case "en":
                _language_name = "English"
                break;
        }
        return _language_name ?? "English";
    }

    static pageUrl = function () {
        return window.location.origin + window.location.pathname;
    }

    static getChilds = (data, parentId, active_only) => {
        var childs = [];
        if (active_only)
            childs = data.filter(x => x.parent?.id === parentId && x.status);
        else
            childs = data.filter(x => x.parent?.id === parentId);
        childs.forEach(item => {
            item.childs = Utils.getChilds(data, item.id, active_only)
        })
        return childs;

    }


    static BuildCategories = (categories, active_only) => {

        if (categories != null) {

            var parents = [];

            if (active_only)
                parents = categories?.filter(x => !x.parent && x.status);
            else
                parents = categories?.filter(x => !x.parent);

            parents.forEach(parent => {
                parent.childs = Utils.getChilds(categories, parent.id, active_only)
            })

            return parents;
        }

    }



    static setCurrentLanguageName = function (languageName) {
        window.localStorage.setItem(Constants.LanguageTokenName, languageName.toLowerCase())
    }

    static clearUrl = function () {
        window.history.pushState({}, document.title, window.location.pathname);
    }

    static getUrlParameter = function (parameter, defaultValue = null) {
        const urlParams = new URLSearchParams(window.location.search);
        let _res = urlParams.get(parameter)
        return _res ? _res : defaultValue;
    }

    static delay = (miliseconds) => {
        return new Promise(resolve => setTimeout(resolve, miliseconds));
    }

    static sortCities = (array) => {
        return array.sort(function (a, b) {
            //console.log(a["names"][0])

            var x = a["names"][0]["name"];
            var y = b["names"][0]["name"];
            return x < y ? -1 : x > y ? 1 : 0;
        });
    }
    static getExtension = (file_name) => {
        if (file_name) return file_name.split(".").pop().toLowerCase();
    }

    static sortByKey = (array, key) => {
        return array.sort(function (a, b) {
            //console.log(a["names"][0])
            var x = a["names"][0]["name"];
            var y = b["names"][0]["name"];

            return x < y ? -1 : x > y ? 1 : 0;
        });
    }

    static sortLanguages = (array, key) => {
        return array.sort(function (a, b) {
            //console.log(a["names"][0])
            var x = a["name"];
            var y = b["name"];

            return x < y ? -1 : x > y ? 1 : 0;
        });
    }

    static sortDictionaryLanguages = (array, key) => {
        let idOrder = [1, 3, 2]

        let  sorted_items = array.sort((a, b) => idOrder.indexOf(a.id) - idOrder.indexOf(b.id));

        return sorted_items
    }


    static MB = function (file_size) {
        if (!file_size) return "";
        var _size;
        if (file_size < 1024 * 1024) {
            _size = Math.floor(file_size / 1024) + ' KB';
        } else {
            _size = Math.floor(file_size / (1024 * 1024)) + ' MB';
        }
        return _size;
    }

    static nl2br(str, is_xhtml) {
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    static getDate = (dateString) => {
        var dateObject = new Date(dateString);
        return dateObject.toDateString();
    };

    static getShortDate = (dateString) => {
        var dateObject = new Date(dateString);
        var year = dateObject.getFullYear();
        var month = ("0" + (dateObject.getMonth() + 1)).slice(-2); // months are 0-based
        var day = ("0" + dateObject.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    };

    static getDay = (dateString) => {
        var dateObject = new Date(dateString);
        return dateObject.getDay();
    };

    static getMonth = (dateString) => {
        var dateObject = new Date(dateString);
        return dateObject.toLocaleString('default', { month: 'long' });
    };

    static formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    static isAuthenticated = function () {
        var user = HttpService.getUser()
        if (!user) return false;
        return true;
    }

    static isAdmin = function () {
        var user = HttpService.getUser()
        if (!user) return false;
        if (user.role === "admin") {
            return true;
        } else return false
    }

    static isEditor = function () {
        var user = HttpService.getUser()
        if (!user) return false;
        if (user.role === "admin" || user.role === "editor") {
            return true;
        } else return false
    }

    static isParticipant = function () {
        var user = HttpService.getUser()
        if (!user) return false;
        if (user.role === "participant") {
            return true;
        } else return false
    }

    static getUserImage = function (user) {

        if (!user) return null;

        let image =  user.image ? user.image : user.avatar ? user.avatar : null;

        
        if(!image && user.social_image){
            image = user.social_image;
        }

        if(!image){
            image =  "/assets/images/user.png";            
        } 

        return image
    }


    static getCurrentLanguageId = () => {
        let lang = localStorage.getItem(Constants.LanguageTokenName);
        //let lang = readCookie("language")
        if (!lang) return 3;

        if (lang === "en") return 3;
        if (lang === "so") return 2;
        if (lang === "kr") return 1;

        return lang;
    };

    static getNameByLanguage = (language_id, item) => {
        if (item.names) {
            var name = item.names.find((x) => x.language == language_id);
            if (name) return name.name;
            else return "";
        }
        return "";
    };

    static getDescriptionByLanguage = (language_id, item) => {
        if (item.names) {
            var name = item.names.find((x) => x.language == language_id);
            if (name) return parse(name.description+"");
            else return "#" + item.key;
        }
        return "#" + item.key;
    };

    static getDefaultValue = (translate) => {
        let language_id = this.getCurrentLanguageId();

        if (translate.values) {
            var default_value = translate.values.find((x) => x.language == language_id);
            if (default_value) return default_value.value;
            else return "#" + translate.key;
        }
        return "#" + translate.key;
    };

    static getDefaultName = (translate) => {
        let language_id = this.getCurrentLanguageId();

        if (translate.names) {
            var default_name = translate.names.find((x) => x.language == language_id);
            if (default_name) return default_name.name;
            else return "#" + translate.key;
        }
        return translate?.key ? "#" + translate.key : "";
    };

    static truncateTextWithEllipsis(text = "", limit_words) {
        if (limit_words > 0 && text.split(" ").length > limit_words) {
            let words = text.split(" ").slice(0, limit_words);
            return words.join(" ") + "...";
        }
        return text;
    }

    static getDefaultDescription = (translate, limit_words = 0) => {
        let language_id = this.getCurrentLanguageId();

        if (translate.names) {
            var default_name = translate.names.find((x) => x.language == language_id);
            if (default_name) {
                let description = default_name.description ?? "";
                description = this.truncateTextWithEllipsis(description + "", limit_words)
                return description;
            }
            else return "#" + translate.key;
        }
        return "#" + translate.key;
    };

    static getDefaultShortDescription = (translate, limit_words = 0) => {
        let language_id = this.getCurrentLanguageId();

        if (translate.names) {
            var default_name = translate.names.find((x) => x.language == language_id);
            if (default_name) {
                let short_description = default_name.short_description ?? "";
                short_description = this.truncateTextWithEllipsis(short_description + "", limit_words)
                return short_description;
            }
            else return "#" + translate.key;
        }
        return "#" + translate.key;
    };



    static setUserSession = (token, user) => {
        localStorage.setItem(HttpService.TOKEN_KEY, token);
        localStorage.setItem("user", JSON.stringify(user));
    };

    static removeUserSession = () => {
        localStorage.removeItem(HttpService.TOKEN_KEY);
        localStorage.removeItem("user");
    };

    static parse = (html, words_limit = 0) => {

        if (words_limit > 0) {
            html = this.truncateTextWithEllipsis(html + "", words_limit)
        }
        return parse(html)
    };

    static parseName = (item, words_limit = 0) => {
        if (!item) return "";
        return parse(Utils.getDefaultName(item) + "")
    };

    static parseDescription = (item, words_limit = 0) => {
        if (!item) return "";
        return parse(Utils.getDefaultDescription(item, words_limit) + "")
    };

    static parseShortDescription = (item, words_limit = 0) => {
        if (!item) return "";
        return parse(Utils.getDefaultShortDescription(item, words_limit) + "")
    };

    static getImage = function (item) {

        if (item && item.image) {
            return item.image;
        }
        return "/assets/images/user.png"

    }

    static getProfileImage = function (profile) {

        if (profile && profile.social_image) {
            return profile.social_image;
        }

        if (profile && profile.avatar) {
            if(profile.avatar.includes("http")){
                return profile.avatar;
            }
            else{
                return HttpService.SiteBase + profile.avatar;
            }
        }

        if (profile && profile.image) {

            if(profile.image.includes("http")){
                return profile.image;
            }
            else{
                return HttpService.SiteBase + profile.image;
            }
        }

        
        return "/assets/images/user.png"

    }



    static timeInSeconds = function (showTime) {

        if (!showTime) return 0;
        // Parsing the time string

        if (showTime?.split(":").length > 1) {
            const [minutes, seconds] = showTime?.split(":").map(Number);
            const showTimeInSeconds = (minutes * 60) + seconds;
            return showTimeInSeconds;

        }

        return 0;


    }

    static isTranscriptVisible = function (transcript, progress = 0) {

        if (!transcript) return false;
        let _time = this.timeInSeconds(transcript?.time);
        let _end_time = this.timeInSeconds(transcript?.end_time);
        if (!_end_time) _end_time = _time + 5

        return progress >= _time && progress < _end_time;

    }

    static isLrcVisible = function (lrc, progress = 0) {

        if (!lrc) return false;
        let _time =  this.timeInSeconds(lrc?.time+"");
        let _end_time = _time + 10

        return progress >= _time  && progress < _end_time;

    }

    static isVideoFullScreen = function () {
        return !!(document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement);
    }





}

