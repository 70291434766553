import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { CourseHttpService } from '../../../../services/course';
import { Utils } from '../../../../utils';
import { Link } from 'react-router-dom';
import { Avatar } from '@mui/material';
import UserCourseProgress from 'view/components/course/user/UserCourseProgress';
import SupportMessageForm from '../forms/shared/SupportMessageForm';
import CustomModal from 'view/components/modals/CustomModal';

function EnrolledUsersList({ courseId }) {
  const [data, setData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [composeModalIsOpen, setComposeModalIsOpen] = useState(false);
  
  const handlePageChange = (newPageNumber) => {
    setPageNumber(newPageNumber);
    _getData(newPageNumber)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  };

  useEffect(() => {

    _getData(1)

    return () => {
    }
  }, [])

  const _getData = (pageNumber) => {

    CourseHttpService.loadEnrolments(pageNumber, courseId).then((items) => {
      if (items.results) {
        setData(items.results)
        setCount(items.count)
        setTotalPages(Math.ceil(items.count / 30));
      }
    });

  }


  return (
    <div className='box'>
      <div className="box-body general-list">
        count: {count}
        <button className="btn btn-icon bg-success" onClick={() => { setComposeModalIsOpen(!composeModalIsOpen) }}><i className="fa fa-envelope "></i></button>

        <table className="table table-striped">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Progress</th>
              {/* <th className='hide-on-small'>Type</th> */}
              <th className='hide-on-small'>Date </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data && data.map(item => (
              <tr key={"people_item_" + item.id}>
                <td><Link ><Avatar alt={Utils.getDefaultName(item)} src={Utils.getUserImage(item.user.profile)} /></Link></td>
                <td className='kfont'>
                  <Link >
                    {item.user.profile.first_name} {item.user.profile.last_name}
                  </Link>
                </td>
                <td title={`${item.progress} %`}>
                  <UserCourseProgress progress={item.progress ?? 0} />
                </td>
                {/* <td className=''>{item.type}</td> */}
                <td className='hide-on-small'>{Utils.getDate(item.created_at)} <br /> <small title='Last login'>{item.last_login && Utils.getDate(item.last_login)}</small> </td>
                <td className='text-right'>

                </td>
              </tr>
            ))}
          </tbody>
          
        </table>
        <ul className="pagination">
            <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(pageNumber - 1)} disabled={pageNumber === 1}>
                <i className="fas fa-angle-left"></i>
              </button>
            </li>
            {Array.from({ length: totalPages }, (_, index) => (
              <li key={index} className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                  {index + 1}
                </button>
              </li>
            ))}
            <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
              <button className="page-link" onClick={() => handlePageChange(pageNumber + 1)} disabled={pageNumber === totalPages}>
                <i className="fas fa-angle-right"></i>
              </button>
            </li>
          </ul>
      </div>

      <CustomModal big key={composeModalIsOpen} setOpen={(value) => setComposeModalIsOpen(value)}
        open={composeModalIsOpen}  >

        <SupportMessageForm courseId={courseId} onDone={() => {  }} />

      </CustomModal>

    </div>
  )
}

export default EnrolledUsersList