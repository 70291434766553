import React, { lazy, Suspense } from 'react'


const CourseCard = lazy(() => import('../components/CourseCard'));

function InstructorCourses({ instructor }) {
    return (
        <section id="about" className="section section-no-border pt-3 pb-3 m-0" >
            <div className="container ">
                <h2 className='mb-3'>Courses</h2>
                <div className="row">
                {instructor?.courses.map((course, i) => (
                    <div className="col-sm-4 col-md-4 mb-4 "  key={course.id}>
                            <CourseCard course={course} showDescription={false} />
                    </div>
                ))}
                </div>
            </div>
        </section>
    )
}

export default InstructorCourses