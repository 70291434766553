import React from 'react'
import { Utils } from '../../../../utils'
import ContentTranscriptSummary from './transcript/ContentTranscriptSummary'
import ContentReviews from './ContentReviews'
import ContentLrcSummary from './transcript/ContentLrcSummary'
import TranslateController from 'controller/shared/TranslateController'

function ContentViewTabs({ content,courseId, onTranscriptClicked,progress=0 }) {

    const {_t} = TranslateController();

    return (
        <div className='hide-on-small'>
            <div className="tabs tabs-simple">
                <ul className="nav nav-tabs tabs-contents  tabs-border-bottom">
                    <li className="nav-item ">
                        <a className="nav-link active" href="#description" data-bs-toggle="tab">{_t("Transcript")}</a>
                    </li>
                   
                    <li className="nav-item">
                        <a className="nav-link" href="#reviews" data-bs-toggle="tab">{_t("Reviews")}</a>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane active p-3" id="description">
                        {/* {content && <ContentTranscriptSummary progress={progress} content={content} onTranscriptClicked={onTranscriptClicked} />} */}
                        {content && <ContentLrcSummary progress={progress} content={content} onTranscriptClicked={onTranscriptClicked} />}
                    </div>
                    
                    <div className="tab-pane p-3" id="reviews">
                        <ContentReviews content={content} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ContentViewTabs