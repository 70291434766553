import { CircularProgress } from '@mui/material';
import React, { useRef, useState } from 'react';
import { CourseHttpService } from 'services/course';
import Done from 'view/cms/components/shared/Done';
import Error from 'view/cms/components/shared/Error';
import LoadingInside from 'view/cms/components/shared/LoadingInside';
import SelectLanguage from 'view/cms/features/feature_blog/components/forms/SelectLanguage';
import LrcList from './LrcList';

function ContentLRCView({ content }) {

    const [isPlaying, setIsPlaying] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showLrcEditor, setShowLrcEditor] = useState(false);
    const [lrcText, setLrcText] = useState('');
    const [lrc, setLrc] = useState('');
    const [language, setLanguage] = useState(null);
    const [done, setDone] = useState(false);
    const [error, setError] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const [lrcData, setLrcData] = useState([]);
    const [canAddLine, setCanAddLine] = useState(false);
    const [showSaveStep, setShowSaveStep] = useState(false);
    
    const lrcTextContainerRef = useRef(null);

    const videoRef = useRef(null);

    const handleVideoStart = () => {

    };

    const handleVideoEnd = () => {

    };

    const pause = () => {
        setIsPlaying(false);
        setCanAddLine(false);
        videoRef.current.pause();
    }

    const play = () => {
        setIsPlaying(true);
        setCanAddLine(true);
        videoRef.current.play();
    }

    const handleAddLine = () => {
        const currentText = document.getElementById('lrctext').value;
        const lines = currentText.split('\n');
        const currentTime = videoRef.current.currentTime;
        const nextLine = lines[lrcData.length]; // Get the next line based on how many are already saved
        const nextIndex = lrcData.length;

        if (nextLine !== undefined) {
            const newLrcData = [...lrcData, { time: currentTime, text: nextLine , index: nextIndex }];
            setLrcData(newLrcData);
            setTimeout(() => {
                scrollToLastBoldLine();
            }, 0);
        }
    };

    const scrollToLastBoldLine = () => {
        const container = lrcTextContainerRef.current;
        if (container) {
            const boldElements = container.querySelectorAll('span[style*="bold"]');
            const lastBoldElement = boldElements[boldElements.length - 1];
            if (lastBoldElement) {
                container.scrollTop = lastBoldElement.offsetTop -300; // Scroll to 150 pixels above the last bold line
            }
        }
    };

    const playPause = () => {
        if (videoRef.current.paused) {
            play();
        } else {
            pause();
        }
    };

    const handleNext = () => {
        setShowLrcEditor(true);
        const textAreaContent = document.getElementById('lrctext').value;
        setLrcText(textAreaContent);
    };

    const handleFinish = () => {
        renderLrcText()
        setShowSaveStep(true);
    };



    const renderTextWithLineBreaks = () => {
        const currentText = document.getElementById('lrctext')?.value ?? "";
        const lines = currentText.split('\n');
        return lines.map((line, index) => {
            // Check both text and index
            const isLineSaved = lrcData.some(lrc => lrc.text === line && lrc.index === index);
            return (
                <React.Fragment key={index}>
                    <span style={{ fontWeight: isLineSaved ? 'bold' : 'normal' }}>{line}</span>
                    <br />
                </React.Fragment>
            );
        });
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `[${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}]`;
    };

    const renderLrcText = () => {
        let lrc = lrcData.map(lrc => `${formatTime(lrc.time)} ${lrc.text}`).join('\n');
        setLrc(lrc);
    };

    const handleValue = (value, name) => {
        switch (name) {
            case "language":
                setLanguage(value)
                break;
            default:
                break;
        }
    }

    const saveChanges = (data) => {

        setError(false)

        if (!language) {
            setError("Please select a language")
            return;
        }

        var body = {
            //   "id": id ? id : data.id,
            "content": content.id,
            "lrc_text": lrc,
            "language": language,
        }

        setDone(false)
        setLoading(true)
        CourseHttpService.saveLrc(body).then(response => {
            console.log(response)
          setLoading(false)
          setDone(true)
          setRefresh(refresh+1)
        }).catch(err => {
          setLoading(false)
          // setError("Something went wrong")
        });
    };

    return (
        <div className="col-lg-12">
            <div className="flex-align-items-center flex-space-between mb-3">
                <h2 className='mb-0'>Lyric</h2>
            </div>

            <div className="row">
                <div className="col-md-4">
                    <video style={{ width: "100%" }} id="transcript_video" poster={content?.image} ref={videoRef} onPlay={handleVideoStart} onEnded={handleVideoEnd} controls >
                        <source src={content?.url ?? "/assets/video/kt.mp4"} type="video/mp4" />
                    </video>
                </div>
                <div className="col-md-8">
                    <div className={`lrc-text-area ${showLrcEditor ? 'hide' : ''}`}>
                        <textarea className="form-control mb-3" id="lrctext" rows="8"></textarea>
                        <button className="btn btn-primary" onClick={handleNext}>
                            Next
                        </button>
                    </div>

                    <div className={`lrceditor ${showLrcEditor ? '' : 'hide'} ${showSaveStep ? 'hide' : ''}`}>
                        <div className="lrc-text-container" ref={lrcTextContainerRef}>
                            {renderTextWithLineBreaks(lrcText)}
                        </div>
                        <div className="flex-align-items-center flex-space-between mb-3">
                            <div className='flex-align-items-center'>
                                {/* {loading && <CircularProgress size={20} color="inherit" />} */}
                                <button className="btn btn-primary" onClick={() => { if (window.confirm('are you sure?')) { setLrcData([]); setShowLrcEditor(false) } }}>
                                    Edit text
                                </button>
                                <button className="btn btn-primary" title='play/pause video' onClick={playPause}>
                                    <i className={`fas ${isPlaying ? 'fa-pause' : 'fa-play'}`}></i>
                                </button>
                                <button className="btn btn-primary" onClick={handleAddLine}>
                                    Next line
                                </button>
                                <button className="btn btn-primary" onClick={handleFinish}>
                                    Finish
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className={`lrc-text-area ${showSaveStep ? '' : 'hide'}`}>
                        <textarea className="form-control mb-3" id="lrcwithtimes" onChange={(e, value) => { setLrc(value) }} value={lrc} rows="8"></textarea>

                        {done && <Done />}
                        {error && <Error message={error} />}

                        <div className="d-flex flex-gap-3">
                            <SelectLanguage callback={handleValue} value={language} />
                            <button className="btn btn-primary" onClick={saveChanges} >
                                Save Changes
                            </button>
                            {loading && <CircularProgress color="inherit" size={30} />}

                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-5">
                <LrcList contentId={content?.id} videoUrl={content?.url} key={"lst"+refresh} />
            </div>
        </div>
    );
}

export default ContentLRCView;
