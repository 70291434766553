import React, { useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { CourseHttpService } from "../../../../../services/course";
import Done from "../../shared/Done";
import Error from "../../shared/Error";
import Loading from "../../shared/Loading";
import { Edit } from "@mui/icons-material";
import NamesWidgets from "../shared/NamesWidgets";
import Gallery from "../../Widgets/shared/Gallery";
import ImageFieldWithGallery from "../../Widgets/shared/ImageFieldWithGallery";
import CustomModal from "view/components/modals/CustomModal";
import UploadToAmazon from "../../Widgets/shared/UploadToAmazon";

function PodcastEpisodeForm({ id, podcastId, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [item_image, setImage] = useState(null);
  const [data, setData] = useState(null);
  const [names, setNames] = useState([]);
  const [uploadModalIsOpen, setUploadModalIsOpen] = useState(false);


  const fileRef = useRef()

  useEffect(() => {
    if (id) {
      CourseHttpService.loadPodcastEpisode(id).then(item => {
        setData(item)
        onEditing(item)
      })
    }
    else {
      // setValue("url", "https://d2avkh8hsb0jpt.cloudfront.net/podcasts/")
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)

    for (const key in item) {
      setValue(key, item[key])
    }

    setNames(item.names)
    setRefresh(refresh + 1)

    setImage(item.image)

  }

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;

    var body = {
      "id": id ? id : data.id,
      "names": names,
      "podcast": podcastId,
      "url": data.url,
      "duration": data.duration,
      "image": item_image
    }

    setDone(false)
    setLoading(true)
    CourseHttpService.savePodcastEpisode(body).then(response => {
      setLoading(false)
      setDone(true)
      setError(false)
      props.onEditDone(response);
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };



  return (
    <div>


      <form className="" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <NamesWidgets key={refresh + "namesw"} names={names} setNames={(names) => { setNames(names) }} />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group d-flex">
              <TextField
                fullWidth
                disabled
                label="Music Url"
                value={watch('url') ?? ""}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("url")}
              />
              <button type="button" className="btn btn-primary ml-2" onClick={() => { setUploadModalIsOpen(true) }}><i className="fa fa-upload"></i></button>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <TextField
                fullWidth
                label="Duration"
                value={watch('duration') ?? "1"}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("duration")}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <ImageFieldWithGallery key={item_image + "itimg"} defaultImageUrl={item_image} onImageSelected={setImage} />
            </div>
          </div>
        </div>
        <div className="form-group mt-3 text-right mb-1">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={editingItem ? <Edit /> : <AddIcon />}
          >
            {editingItem ? "Save Changes" : "Add Episode"}
          </Button>


        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <Loading />}
        </div>
      </form>


      <CustomModal className="" big key={uploadModalIsOpen} setOpen={(value) => setUploadModalIsOpen(value)}
        open={uploadModalIsOpen} >

        <UploadToAmazon
          uploadFolder={`podcasts/${podcastId}`}
          onEditDone={(url) => {
            setValue('url',url)
            setTimeout(() => {
              setUploadModalIsOpen(false)
            }, 1000)
          }}
        />

      </CustomModal>
    </div>
  );
}

export default PodcastEpisodeForm;
