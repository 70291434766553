import { CircularProgress } from '@mui/material';
import React, { useState } from 'react'

import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { BaseHttpService } from 'services/base';
import { HttpService } from 'services/http';
import { Utils } from 'utils';
function DictionaryByCharacter({ startLetter, query = "" }) {

    const [dictionary_entries, setData] = useState(null);


    const { isLoading, error, data, refetch } = useQuery(['dictionary_entries', startLetter, query], () => { return BaseHttpService.loadDictionaryEntries(startLetter, query) }, {
        onSuccess: (data) => {
            setData(data.results)
        },
        // cacheTime: 60*60*24*30*1000// Cache results for 30 days
        refetchInterval: HttpService.DefaultRefetchInterval,
    });

    if (dictionary_entries && dictionary_entries.length == 0) return;
    return (
        <div className="dictionary-container">
            {isLoading && (
                <li className='text-center'>
                    <CircularProgress size={15} />
                </li>
            )}
            <div className='dictionary-title'>
                <div className="spacer">&nbsp;</div>
                <div className="title">{startLetter}</div>
                <div className="spacer">&nbsp;</div>
            </div>
            {dictionary_entries && dictionary_entries.map((item, index) => (
                <div className='dictionary-entry'>
                    <div className='kfont text-right'>
                        <p className='mb-1 font-weight-bold'>{Utils.getNameByLanguage(3, item)}</p>
                        <p className='mb-0'>{Utils.getDescriptionByLanguage(3, item)}</p>
                    </div>
                    <div className=''>
                        <p className='mb-1 font-weight-bold'>{Utils.getNameByLanguage(1, item)}</p>
                        <p className='mb-0'>{Utils.getDescriptionByLanguage(1, item)}</p>
                    </div>

                    {/* <td className='kfont'>{Utils.getNameByLanguage(2, item)}</td> */}
                </div>
            ))}
            {(data?.count > 100) && (
                <div className="dictionary-more"><Link title='More'><i className="fa fa-chevron-down"></i></Link></div>
            )}
        </div>
    )
}

export default DictionaryByCharacter