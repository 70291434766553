import React from 'react'

function UserCourseProgress({progress=0}) {
  return (
    <div className="progress">
      <div className="progress-bar progress-bar-primary" data-appear-progress-animation={`${progress}%`} data-appear-animation-delay="20" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{width: progress+"%", marginLeft: progress>0 ? 0 : "10px"}}>
        {parseInt(progress)}%
      </div>
    </div>
  )
}

export default UserCourseProgress