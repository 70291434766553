import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import TranslateController from 'controller/shared/TranslateController';
import { Utils } from 'utils';
import AdminView, { EditorView } from './Permissions';

function CmsHeader({ transparent = false }) {


	const { changeLanguage } = useContext(LanguageContext);
	const {_t} = TranslateController();
	return (
		<header id="header" className={`${transparent ? "header-transparent header-effect-shrink" : ""} bg-dark mb-header `} data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyChangeLogo': true, 'stickyStartAt': 30, 'stickyHeaderContainerHeight': 70}">
			<div className="header-body border-top-0 bg-black box-shadow-none h-auto">
				<div className="header-container container">
					<div className="header-row">
						<div className="header-column">
							<div className="header-row">
								<div className="header-logo">
									<a href="/cms">
										<img alt="Porto" width="82" height="40" src="/assets/images/logo.png" />
									</a>
								</div>
							</div>
						</div>
						<div className="header-column justify-content-end">
							<div className="header-row">
								<div className="header-nav header-nav-links header-nav-dropdowns-dark header-nav-light-text order-2 order-lg-1">
									<div className="header-nav-main header-nav-main-mobile-dark header-nav-main-square header-nav-main-dropdown-no-borders header-nav-main-effect-2 header-nav-main-sub-effect-1">
										<nav className="collapse">
											<ul className="nav nav-pills" id="mainNav">
												{/* <li >
													<Link className="dropdown-item  lan-kfont dropdown-toggle " to="/cms/dashboard">
														{_t("Dashboard")}
													</Link>
												</li> */}
												<AdminView>
													<li className="dropdown">
														<Link to={"/cms/course/"} className="dropdown-item  lan-kfont dropdown-toggle ">
															Courses
														</Link>
														<ul className="dropdown-menu">
															<li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/course/`}>Courses</Link></li>
															<li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/review/`}>Reviews</Link></li>
															<li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/levels/`}>Levels</Link></li>
														</ul>
													</li>
												</AdminView>
												<AdminView>
													<li className="dropdown">
														<Link to={"/cms/podcast/"} className="dropdown-item  lan-kfont dropdown-toggle ">
															Podcasts
														</Link>
														<ul className="dropdown-menu">
															<li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/podcast/`}>Podcasts</Link></li>
															<li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/podcast/categoty/`}>Categories</Link></li>
														</ul>
													</li>
												</AdminView>
												<EditorView>
													<li className="dropdown">
														<Link to={"/cms/notation/"} className="dropdown-item  lan-kfont dropdown-toggle ">
															Notation
														</Link>
														<AdminView>
															<ul className="dropdown-menu">
																<li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/notation/`}>Notation</Link></li>
																<li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/notation/scale/`}>Scales</Link></li>
																<li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/notation/time-signature/`}>Time Signatures</Link></li>
															</ul>
														</AdminView>
													</li>
												</EditorView>
												<EditorView>
													<li className="dropdown">
														<Link to="/cms/posts" className="dropdown-item lan-kfont  lan-kfont dropdown-toggle ">
															Articles
														</Link>
														<ul className="dropdown-menu">
															<li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/posts/`}>All Posts</Link></li>
															<AdminView>
																<li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/category/`}>Categories</Link></li>
															</AdminView>
															{/* <li className="kfont"><Link className="dropdown-item lan-kfont" to={`/cms/author/`}>Authors</Link></li> */}
														</ul>
													</li>
												</EditorView>
												<li className="dropdown">
													<Link to="/cms/settings" className="dropdown-item lan-kfont">
														Settings
													</Link>
													
												</li>
												
												<li className="dropdown hide-on-small">
													<Link className="dropdown-item lan-kfont dropdown-toggle lan-kfont">
														{/* {Utils.getCurrentLanguageNameFull()} */}
														<i className="fa fa-globe font-size-20 opacity-7"></i> &nbsp; 
													</Link>
													<ul className="dropdown-menu">
														<li onClick={(e) => { e.preventDefault(); changeLanguage("so") }} className="kfont"><Link className="dropdown-item lan-kfont" >کوردی</Link></li>
														<li onClick={(e) => { e.preventDefault(); changeLanguage("kr") }} ><Link className="dropdown-item lan-kfont" >Kurdî</Link></li>
														<li onClick={(e) => { e.preventDefault(); changeLanguage("en") }} ><Link className="dropdown-item lan-kfont" >English</Link></li>
													</ul>
												</li>
												<li >
													<a className="" href="/?signout">
														<i className="fa fa-sign-out"></i>
													</a>
												</li>
											</ul>
										</nav>
									</div>
									<button className="btn header-btn-collapse-nav" data-bs-toggle="collapse" data-bs-target=".header-nav-main nav">
										<i className="fas fa-bars"></i>
									</button>
								</div>
								{/* <div className="header-nav-features header-nav-features-light header-nav-features-no-border header-nav-features-lg-show-border order-1 order-lg-2">
									<div className="header-nav-feature header-nav-features-search d-inline-flex">
										<a href="#" className="header-nav-features-toggle text-decoration-none" data-focus="headerSearch"><i className="fas fa-search header-nav-top-icon"></i></a>
										<div className="header-nav-features-dropdown header-nav-features-dropdown-mobile-fixed" id="headerTopSearchDropdown">
											<form role="search" action="page-search-results.html" method="get">
												<div className="simple-search input-group">
													<input className="form-control text-1" id="headerSearch" name="q" type="search" placeholder="Search..." />
													<span className="input-group-append">
														<button className="btn" type="submit">
															<i className="fas fa-search header-nav-top-icon"></i>
														</button>
													</span>
												</div>
											</form>
										</div>
									</div>

								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>

		</header>
	)
}

export default CmsHeader